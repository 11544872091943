import * as React from "react";
import  { useEffect, useState } from 'react';
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import { Typography, Button } from "@mui/material";
import Tab1 from "../components/SystemConfig/Tab1";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab2 from "../components/SystemConfig/Tab2";
import Tab3 from "../components/SystemConfig/Tab3";
import Tab4 from "../components/SystemConfig/Tab4";
import Tab5 from "../components/SystemConfig/Tab5";
import styles from "../styles/global";


export default function SystemConfig() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  


  // Set shouldRefresh to true to trigger a page refresh
  
  // React.useEffect(() => {
  //     window.location.reload();
  //     setInterval(500000);
  // },[])

  return (
    <Box sx={{ width: "100%" }}>
      <Navbar />
      <Box sx={Styles.topContainer}>
        <Typography sx={Styles.largeTxt}>System Configuration</Typography>
      
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box sx={Styles.tabsBox}>
          <TabContext value={value}>
            <Box
              sx={{
                width: "100%",
               
                borderBottom: 1,
                borderColor: "#999999",
                // overflowY: "scroll",
              }}
            >
              <TabList
                TabIndicatorProps={{ sx: { backgroundColor: "#a32c26" } }}
                sx={{
                  // overflowY: "scroll",
                  "& button:hover": {
                    backgroundColor: "#999999",
                    color: "white",
                  },
                  "& button:focus": {
                    backgroundColor: "#005e89",
                    fontFamily: "MontserratRegular",
                    color: "white",
                  },
                  "& button.Mui-selected": {
                    backgroundColor: "#005e89",
                    fontFamily: "MontserratRegular",
                    color: "white",
                  },
                }}
                onChange={handleChange}
           
                aria-label="lab API tabs example"
              >
                <Tab
                  sx={Styles.tabsstyle}
                
                  label="Class Type"
                  value="1"
              
                />
                <Tab
                  sx={Styles.tabsstyle}
                  label="Class Category"
                  value="2"
                 
                />
                <Tab
                  sx={Styles.tabsstyle}
                  label="Course Type"
                  value="3"
                 
                />
                <Tab
                  sx={Styles.tabsstyle}
                  label="Course Category"
                  value="4"
                 
                />
                <Tab
                  sx={Styles.tabsstyle}
                  label="Facilitator Roles"
                  value="5"
           
                />
                {/* <Tab
                  sx={{
                    fontFamily: "MontserratSemiBold",
                    border: "1px solid #999999",
                  }}
                  label="Add Facilitator"
                  value="6"
                /> */}

                
              </TabList>
            </Box>
            <TabPanel sx={Styles.tab} value="1" >
            <Tab1 />
            </TabPanel>
            <TabPanel sx={Styles.tab} value="2">
              <Tab2 />
            </TabPanel>
            <TabPanel sx={Styles.tab} value="3">
              <Tab3 />
            </TabPanel>
            <TabPanel sx={Styles.tab} value="4">
              <Tab4 />
            </TabPanel>
            <TabPanel sx={Styles.tab} value="5">
              <Tab5 />
            </TabPanel>
            {/* <TabPanel style={Styles.tab} value="6">
              <Tab6 />
            </TabPanel> */}
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
}
const Styles = {
  topContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // minWidth: "336px",
    margin: "auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  tabsBox: {
    width: "95%",
    // border: "1px solid #999999",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px 10px 0px 0px",
    marginTop: "25px",
    // overflow: "scroll",
    height:'30%'
    // padding:"0px"
  },
  tab: {
    padding: "0px",
    
  },
  tabsstyle:{

     fontFamily: "MontserratSemiBold",
    //  border: "1px solid #999999",
     width:'12%',
      height:'70px',
     
  }
};