import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import UserNavbar from "../UserNavbar";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import uploadimg from '../../Images'
import uploadimg from "../../../images/uploadImg.png";
import ConfirmPasswordPopUp from "./ConfirmPasswordPopUp";
import Navbar from "../../../components/Navbar";
// const uploadimg = require('../../../../images/uploadImg.png');

export default function UserProfile() {
  const [image, setImage] = useState(null);

  const [openpop, setOpenpop] = useState(false);
  
  const openPopUp = () => {
    setOpenpop(!openpop);
  };

  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      setImage(URL.createObjectURL(event.target.files[0]));
    };
    input.click();
  };

  const [data, setData] = useState({});

  const handleDateChange = (key, value) => {
    
    setData((prevData) => {
      return {
        ...prevData,
        [key]: value,
      };
    });
  };
  const handleChange = (key, value) => {
    // console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };
  const handleChangePass = () => {
    // console.log('first')
  };
  console.log(data);

  

  return (
    <Box sx={{ width: "100%" }}>
      {/* <UserNavbar /> */}
      <Navbar/>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: 3,
        }}
      >
        <Box sx={{ mx: 2, my: 0.5 }}>
          <Typography sx={{ fontSize: 20 }}>Dashboard {">"} Profile</Typography>
        </Box>
        <Box sx={{ mx: 2, my: 0.5 }}>
          <Button sx={{ color: "#FFFFFF",
          backgroundColor: "#999999", border: 1,  }}  onClick={openPopUp} >
            Change Password
          </Button>
        </Box>
      </Box>

      {openpop && <ConfirmPasswordPopUp open={openpop} setOpen={setOpenpop} />}

      {/* profile content */}
      <Box
        sx={{
          height: "50%",
          width: "95%",
          margin: "auto",
          marginTop: "25px",
          border: 1,
          borderColor: "#999999",
          borderRadius: 2,
        }}
      >
        <Box>
          <Box
            sx={{
              height: 50,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              borderBottom: 1,
              borderColor: "#999999",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                mx: 1,
              }}
            >
              <Typography
                sx={{ fontSize: 18, fontFamily: "MontserratRegular" }}
              >
                {" "}
                Profile Details{" "}
              </Typography>
            </Box>
            <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
              <Button
                onClick={handleChangePass}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor:'#005e89',
                  border: 1,
                  height: 30,
                  fontFamily: "MontserratRegular",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
          {/* <Box sx={{width:'100%' , border:0.1 , borderColor:'#999999'}} ></Box> */}
        </Box>
        {/* horizontal3 Boxes */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent:'space-around',
            "@media (max-width:768px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              m: 1,
              width: "30%",
              flexDirection: "column",
              "@media (max-width:768px)": {
                width: "95%",
                margin: "auto",
              },
            }}
          >
            <TextField
              id="outlined-basic"
              size="small"
              sx={Styles.inputs}
              placeholder="Name"
              variant="outlined"
              value={data.name}
              onChange={(e) => {
                handleChange("name", e.target.value);
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                label="Birth Date"
                value={data.date}
                // onChange={(e) => {
                //   handleChange("date", e.target.value);
                // }}
                sx={{
                  m: 1,
                  minWidth: "100px",
                  // width: "420px",
                  borderRadius: "10px",
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                    height: "40px",
                    minWidth: "100px",
                    borderRadius: "10px",
                  },
                  "& .MuiFormControl-root MuiTextField-root css-kiy1u2-MuiFormControl-root-MuiTextField-root":
                    {
                      // height: "20px",
                      backgroundColor: "red",
                    },
                  "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    fontSize: "14px",
                    lineHeight: "10px",
                  },
                }}
              />
            </LocalizationProvider>

            {/* <TextField
              value={data.date}
              id="outlined-basic"
              size="small"

              onChange={(e) => {
                handleDateChange("date", e.target.value);
              }}
              type="date"
             sx={Styles.inputs}
              variant="outlined"
              //   label="Date of Birth"
              InputLabel='hello'
              placeholder="hello"
              InputProps={{
                placeholder: '',
              }}
            /> */}
            <TextField
              id="outlined-basic"
              size="small"
              multiline
              sx={Styles.inputs}
              placeholder="Address line 1"
              value={data.address_line_1}
              variant="outlined"
              onChange={(e) => {
                handleDateChange("addres_line_1", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              size="small"
              multiline
              sx={Styles.inputs}
              placeholder="City"
              value={data.city}
              variant="outlined"
              onChange={(e) => {
                handleDateChange("city", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              size="small"
              multiline
              sx={Styles.inputs}
              placeholder="State/Provience"
              value={data.state_provience}
              variant="outlined"
              onChange={(e) => {
                handleDateChange("addres_line_1", e.target.value);
              }}
            />
            <TextField
              id="outlined-multiline-static"
              sx={Styles.inputs}
              multiline
              rows={3}
              //   defaultValue="Default Value"
              value={data.bio}
              onChange={(e) => {
                handleDateChange("bio", e.target.value);
              }}
              placeholder="Bio"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              m: 1,
              width: "30%",
              flexDirection: "column",
              "@media (max-width:768px)": {
                width: "95%",
                margin: " auto",
              },
            }}
          >
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="Email Id"
              variant="outlined"
              value={data.email_id}
              onChange={(e) => {
                handleDateChange("email_id", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="Phone Number"
              variant="outlined"
              value={data.phone_number}
              onChange={(e) => {
                handleDateChange("phone_number", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="Address line 2"
              variant="outlined"
              value={data.address_line_2}
              onChange={(e) => {
                handleDateChange("address_line_2", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="Postal Code"
              variant="outlined"
              value={data.postal_code}
              onChange={(e) => {
                handleDateChange("postal_code", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="Country"
              variant="outlined"
              value={data.country}
              onChange={(e) => {
                handleDateChange("country", e.target.value);
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
               
              }}
            >
              <RadioGroup
                row
                // aria-labelledby="demo-row-radio-buttons-group-label"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "80%",
                }}
                name="row-radio-buttons-group"
              >
                <Typography sx={{ marginRight:0.1, color: "#999999" }}>Gender</Typography>
                <FormControlLabel
                  sx={{
                    color: "#999999",
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#005e89" },
                  }}
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#005e89" },
                  }}
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  sx={{
                    color: "#999999",
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#005e89" },
                  }}
                  value="Other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              m: 2,
              width: "30%",
              flexDirection: "column",
              "@media (max-width:768px)": {
                width: "92%",
                margin: "auto",
                marginTop: "15px",
                marginBottom: "15px",
              },
            }}
          >
            <Box
              sx={{
                border: 1,
                height: "55%",
                borderRadius: 2,
                borderColor: "#999999",
              }}
            >
              <Typography sx={Styles.uploadText}>Upload Photo</Typography>
              {image ? (
                <Box sx={Styles.uploaded}>
                  <img
                    style={Styles.uploadLogo}
                    src={image}
                    alt="uploaded image"
                  />
                </Box>
              ) : (
                <img
                  style={Styles.uploadLogo}
                  src={uploadimg}
                  alt="img"
                  onClick={handleImageClick}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <Button
            sx={{
              width: "25%",
              border: 1,
              my: 1,
              mx:3,
              backgroundColor: "#005e89",
              color: "white",
            }}
          >
            Update Profile
          </Button>
        </Box>
      </Box>

      {/* profileContent */}
    </Box>
  );
}

const Styles = {
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minWidth: "336px",
    margin: "auto",
    height: "8vh",
  },
  mainBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  firstBox: {
    marginTop: "25px",
    width: "95%",
    height: "570px",
    border: "1px solid #999999",
    borderRadius: "10px",
  },
  headerBox: {
    width: "100%",
    // height:"45px",
    borderBottom: "1px solid #999999",
    alignItems: "center",
    // padding:"auto"
  },
  headerText: {
    margin: "15px",
    // marginLeft:"15px",
    padding: "auto",
    alignItems: "center",
    fontSize: "14px",
    color: "#252525",
    fontWeight: "bold",
    fontFamily: "MontserratRegular",
  },
  secondBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  inputs: {
    m: 1,
    "& .MuiInputBase-root": {
      fontFamily: "MontserratRegular",
      //  height:"20px"
      borderRadius: "10px",
    },
  },
  uploadPhoto: {
    width: "95%",
    height: "150px",
    border: "1px solid #999999",
    borderRadius: "10px",
    // display:"flex",
    // justifyContent:"center",
  },
  uploadText: {
    margin: "10px",
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    color: "#999999",
  },
  uploadLogo: {
    width: "80px",
    marginLeft: "40%",
    cursor: "pointer",
  },
  uploaded: {
    width: "100%",
    bottom: "0px",
  },
};
