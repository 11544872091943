import {
  Box,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";

import moment from "moment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";

import { React, useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { gql, useQuery, useMutation } from "@apollo/client";

const GET_EVENT_TYPE = gql`
  query GetEventType {
    event_type {
      created_by

      created_date

      description

      id

      name
    }
  }
`;

const GET_EVENT_CATEGORY = gql`
  query GetEventCategory {
    event_category {
      created_by

      created_date

      description

      id

      name
    }
  }
`;

const INSERT_EVENT_HEADER_INFO_MUTATION = gql`
  mutation InsertEventHeaderInfo(
    $category_id: bigint
    $name: String
    $activation_date: date
    $description: String
    $deactivation_date: date
    $recurrance_start_date: date
    $recurrance_end_date: date
    $recurrance_frequency: String
    $type_id: bigint
    $location: String
    $start_time: timetz
    $end_time: timetz
    $is_recurring: Boolean
    $status: String
    $recording_status: Boolean
    $conven_meeting: Boolean

  ) {
    insert_event_master(
      objects: {
        category_id: $category_id
        name: $name
        activation_date: $activation_date
        description: $description
        deactivation_date: $deactivation_date
        recurrance_end_date: $recurrance_end_date
        recurrance_start_date: $recurrance_start_date
        recurrance_frequency: $recurrance_frequency
        type_id: $type_id
        location: $location
        start_time: $start_time
        end_time: $end_time
        is_recurring: $is_recurring
        status: $status
        recording_status:$recording_status
        conven_meeting:$conven_meeting

      }
    ) {
      returning {
        activation_date
        category_id
        created_at
        deactivation_date
        description
        id
        metadata
        name
        recurrance_end_date
        recurrance_frequency
        recurrance_start_date
        type_id
        updated_at
        location
        is_recurring
        start_time
        end_time
        recording_status
        conven_meeting
      }
    }
  }
`;

const INSERT_RECEVENT_HEADER_INFO_MUTATION = gql`
  mutation InsertRecEventHeaderInfo(
    $category_id: bigint
    $name: String
    $activation_date: date
    $description: String
    $deactivation_date: date
    $recurrance_start_date: date
    $recurrance_end_date: date
    $recurrance_frequency: String
    $type_id: bigint
    $location: String
    $start_time: timetz
    $end_time: timetz
    $is_recurring: Boolean
    $parent_id: bigint
    $publish_status: String
    $recording_status: Boolean
    $conven_meeting: Boolean
  ) {
    insert_event_master(
      objects: {
        category_id: $category_id
        name: $name
        activation_date: $activation_date
        description: $description
        deactivation_date: $deactivation_date
        recurrance_end_date: $recurrance_end_date
        recurrance_start_date: $recurrance_start_date
        recurrance_frequency: $recurrance_frequency
        type_id: $type_id
        location: $location
        start_time: $start_time
        end_time: $end_time
        is_recurring: $is_recurring
        parent_id: $parent_id
        publish_status:$publish_status
        recording_status:$recording_status
        conven_meeting:$conven_meeting
      }
    ) {
      returning {
        activation_date
        publish_status
        category_id
        recording_status
        created_at
        conven_meeting
        deactivation_date

        description

        id

        metadata

        name

        recurrance_end_date

        recurrance_frequency

        recurrance_start_date

        type_id

        updated_at

        location

        is_recurring

        start_time

        end_time

        parent_id
      }
    }
  }
`;

const theme = createTheme({
  components: {
    MuiPickersStaticWrapper: {
      styleOverrides: {
        root: {
          "& .MuiPickersBasePicker-pickerView": {
            padding: "0px",

            height: "10px",
          },
        },
      },
    },
  },
});

const frequency = [
  { id: 1, name: null },

  { id: 2, name: "Daily" },

  { id: 3, name: "Weekly" },

  { id: 4, name: "Monthly" },

  { id: 5, name: "Yearly" },
];

export default function InsttuteForm(props) {
  const [event, setEvent] = useState("no");
  const [users, setUsers] = useState([]);
  const [showBox, setShowBox] = useState(false);
  const newData=props.data;
  const newDate=props.date;
  const [showRadioGroup, setShowRadioGroup] = useState(false);
  const [eventMode, setEventMode] = useState(false);
  const [vcMode, setVcMode] = useState(true);
console.log("videocall mode: " ,vcMode);

  const handleEvent = (event) => {
    const selectedEvent = event.target.value;
    // console.log(selectedEvent);
    setEvent(selectedEvent);
    setShowBox(selectedEvent === "yes");
    if (selectedEvent === "yes") {
      handleChange("recurringEvent", true);
    } else {
      handleChange("recurringEvent", false);
    }
  };

  //Fetching users data

  // console.log("event", event);
  const [eventCat, setEventCat] = useState([]);
  const [eventType, setEventType] = useState([]);
  //............................FETCH EVENT TYPE & CATEGORY................................
  const {
    loading: eventTypeLoading,

    error: eventTypeError,

    data: eventTypeData,
  } = useQuery(GET_EVENT_TYPE);

  const {
    loading: eventCatLoading,

    error: eventCatError,

    data: eventCatData,
  } = useQuery(GET_EVENT_CATEGORY);

  useEffect(() => {
    setEventType(eventTypeData?.event_type);

    // console.log("inside use effect event type", eventTypeData?.event_type);

    setEventCat(eventCatData?.event_category);
  }, [eventTypeData, eventCatData]);

  // console.log("event category category map", eventCatData?.event_category);

  //............................FETCH EVENT TYPE & CATEGORY................................

  const [errorDescription, seterrorDescription] = useState(false);

  const [errorEvent, setErrorEvent] = useState(false);

  const [errorLocation, setErrorLocation] = useState(false);

  const [formData, setFormData] = useState(newData);
  const handleChange = (key, value) => {
    console.log(key, value);
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    props.setData((prevData) => ({
      ...prevData,
      [key]: value,
      "callmode":vcMode
    }));
    console.log("formdata", formData); // This will still log the previous state because setState is asynchronous
  };
  
  const handleTimeChange = (key, value) => {
    let formattedTime = value.format("hh:mm a");
    let forTime2 = value.format("hh:mm:ss")
    
    // console.log(formattedTime,"key value",forTime2);
    setFormData((prevData) => ({
      ...prevData,
      [key]: formattedTime,
      
    }));
    props.setDate((prevData) => ({
      ...prevData,
      [key]: formattedTime,
    }));
    props.setstartTimeConvene((prevData) => ({
      ...prevData,
      [key]: forTime2,
    }));
  };
  
  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    console.log(formattedDate);
    setFormData((prevData) => ({
      ...prevData,
      [key]: formattedDate,
    }));
    props.setDate((prevData) => ({
      ...prevData,
      [key]: formattedDate,
    }));
  };
  

  const [headerInfo, setHeaderInfo] = useState([]);

  const [errors, setErrors] = useState({});

  const [insertEventHeaderInfo, { loading, error }] = useMutation(
    INSERT_EVENT_HEADER_INFO_MUTATION,

    {
      onError: (error) => {
        console.error("Mutation Error:", error.message);
      },

      onCompleted: (data) => {
        console.log("pratik", data.insert_event_master.returning[0]);

        localStorage.setItem(
          "event_id",

          data.insert_event_master.returning[0].id
        );

        console.log(
          "event_id hi hi hi ",
          data.insert_event_master.returning[0].id
        );

        // handleNext();

        handleNext();

        // localStorage.setItem(

        //   "group_id",

        //   data.insert_group_master.returning[0].id

        // );
      },
    }
  );

  const handleNext = () => {
    console.log(props.data);

    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const addData = async () => {
    const newErrors = {};
    if (!formData.eventname_em) {
      newErrors.eventname_em = "Class Name is required";
    }

    if (!formData.category) {
      newErrors.category = "Category Name is required";
    }
    if (!formData.eventType) {
      newErrors.eventType = "type is required";
    }
    if (!formData.location) {
      newErrors.location = "Location is required";
    }
    if (!formData.startTime) {
      newErrors.startTime = "startTime is required";
    }
    if (!formData.startDate) {
      newErrors.startDate = "Start date is required";
    }
    // if (!formData.start_date) {
    //   newErrors.start_date = "Start date is required";
    // }
    // Error

    if (!formData.endDate) {
      // console.log(formData.endDate)

      newErrors.endDate = "End date is required";
    }
    // if (!formData.duration) {

    //   newErrors.duration = "duration is required";

    // }

    // price erroe

    if (!formData.price) {
      newErrors.price = "price is required";
    }
    if (!formData.description) {
      console.log("Error:");
      newErrors.description = "Description is required";
    }
    setErrors(newErrors);

    try {
      const { data: insertedData } = await insertEventHeaderInfo({
        variables: {
          description: formData["description"],

          name: formData["eventname_em"],

          recurrance_start_date: formData["startDate"]?formData["startDate"] : newDate["startDate"],

          recurrance_end_date: formData["endDate"] ? formData["endDate"] : newDate["endDate"],

          recurrance_frequency: formData["frequency"] || "",

          category_id: formData["category"],

          type_id: formData["eventType"],

          location: formData["location"],

          is_recurring: event,

          start_time: formData["startTime"]? formData["startTime"]:newDate["startTime"],

          end_time: formData["endTime"]? formData["endTime"]:newDate["endTime"],
          recording_status:eventMode?eventMode:false,
          conven_meeting:vcMode
          // status: "isactive",
        },
      });

      console.log("instered event header info", insertedData);

      if (event === "yes") {
        console.log("run for loop ", event);

        handleInsertQuery();
      }
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

    // }
  };

  // .................................recurring Api...........................................

  const [insertRecEventHeaderInfo, { loading1, error1 }] = useMutation(
    INSERT_RECEVENT_HEADER_INFO_MUTATION,

    {
      onError: (error) => {
        console.error("Mutation Error:", error);
      },

      onCompleted: (data) => {
        console.log("pratik", data.insert_event_master.returning[0]);

        // localStorage.setItem(
        //   "event_id",

        //   data.insert_event_master.returning[0].id
        // );

        console.log(
          "event_id hi hi hi ",
          data.insert_event_master.returning[0].id
        );

        // handleNext();

        // localStorage.setItem(

        //   "group_id",

        //   data.insert_group_master.returning[0].id

        // );
      },
    }
  );

  const [curDate, setcurDate] = useState();

  const handleInsertQuery = async () => {
    console.log("handleInsertQuery is called");

    const startDate = new Date(formData["startDate"]); // Convert startDate to Date object

    const endDate = new Date(formData["endDate"]); // Convert endDate to Date object

    const calculateDateDifference = () => {
      if (formData.startDate && formData.endDate) {
        const start = moment(formData.startDate);

        const end = moment(formData.endDate);

        const duration = moment.duration(end.diff(start));

        const daysDiff = duration.asDays();

        console.log("Difference between start and end dates:", daysDiff);

        return daysDiff;
      }
    };

    const dateDifference = calculateDateDifference();

    // console.log("datedifference", dateDifference);

    for (let i = 1; i <= dateDifference; i++) {
      console.log("value of i ", i);

      const currentDate = new Date(startDate);

      console.log("current date", currentDate);

      if (formData["frequency"] === "Daily") {
        currentDate.setDate(currentDate.getDate() + i);

        // console.log("freq", formData["frequency"]);
      } else if (formData["frequency"] === "Weekly") {
        currentDate.setDate(currentDate.getDate() + i * 7);

        console.log("freq", formData["frequency"]);

        console.log("currrent date", currentDate);
      } else if (formData["frequency"] === "Monthly") {
        currentDate.setMonth(currentDate.getMonth() + i);

        console.log("freq", formData["frequency"]);

        console.log("currrent date", currentDate);
      } else if (formData["frequency"] === "Yearly") {
        currentDate.setFullYear(currentDate.getFullYear() + i);

        console.log("freq", formData["frequency"]);

        console.log("currrent date", currentDate);
      } else {
        break;
      }

      if (currentDate > endDate) {
        break; // Exit the loop if currentDate exceeds endDate
      }

      handleInsertQuery1(currentDate);

      console.log("insertd rec event header");
    }
  };

  const handleInsertQuery1 = async (currentDate) => {
    try {
      const { data: insertedRecData } = await insertRecEventHeaderInfo({
        variables: {
          parent_id: localStorage.getItem("event_id"),

          description: formData["description"],

          name: formData["eventname_em"],

          recurrance_start_date: currentDate,

          recurrance_end_date: currentDate,

          recurrance_frequency: formData["recurrance_frequency"] || "",

          category_id: formData["category"],

          type_id: formData["eventType"],

          location: formData["location"],

          is_recurring: false,

          start_time: formData["startTime"],

          end_time: formData["endTime"],
          publish_status:"published",
          recording_status:eventMode?eventMode:false,
          conven_meeting:vcMode
        },
      });

      console.log("instered event header info", insertedRecData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

    // }
  };

  // .................................recurring Api...........................................

  return (
    <Box
      sx={{
        display: "flex",

        flexDirection: "row",

        maxWidth: "800px",

        flexWrap: "wrap",

        "& .MuiInputBase-root": {
          fontFamily: "MontserratRegular",
        },
      }}
    >
      {/* ...................left box */}

      <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "400px" }}>
        <Box sx={{ margin: "0px 0px 4px 0px" }}>
          <TextField
            sx={{
              width: "360px",

              mt: 0,

              mb: 0,

              mx: 1,

              minWidth: "360px",

              fontFamily: "MontserratRegular",

              borderRadius: "50%",

              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
              },

              "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                fontFamily: "MontserratRegular",

                color: " #999999",

                fontSize: "14px",

                lineHeight: " 20px",
              },
            }}
            required
            value={formData.eventname_em}
            size="small"
            onChange={(e) => {
              handleChange("eventname_em", e.target.value);
            }}
            id="standard-required"
            label="Class Name"
            // placeholder="Event Name"

            error={!!errors.eventname_em}
            helperText={errors.eventname_em}
          />
        </Box>

        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              size="small"
              // label="Enter start Date"
              label={newDate?.startDate?newDate?.startDate:"Enter start date"}

              inputFormat="YYYY/MM/DD"
              format="YYYY/MM/DD"
              sx={{
                width: "360px",
                marginTop: "15px",
                ml: "8px",
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",

                  height: "40px",
                },

                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",

                  color: "#999999",

                  fontSize: "14px",

                  lineHeight: "10px",
                },
              }}
              onChange={(e) => {
                handleDateChange("startDate", e);
              }}

              value={formData.startDate}
              error={!!errors.startDate}
              helperText={errors.startDate}
              renderInput={(params) => (
                <TextField size="small" {...params} placeholder="Event Name" />
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>

        {!showBox && (
          <Box sx={{ display: "flex", maxWidth: "360px", mx: 1 }}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  size="Start Time"
                  // label="Enter start time"
                  label={newDate?.startTime?newDate?.startTime:"Enter start time"}

                  ap={true}
                  format="h:mm A "
                  sx={{
                    width: "360px",
                    mt: "15px",
                    "& .MuiInputBase-root": {
                      fontFamily: "MontserratRegular",

                      height: "40px",
                    },

                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                      fontFamily: "MontserratRegular",

                      color: "#999999",

                      fontSize: "14px",

                      lineHeight: "10px",
                    },
                  }}
                  value={formData.startTime || null}
                  onChange={(e) => {
                    handleTimeChange("startTime", e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      placeholder="Event Name"
                      inputProps={{
                        inputMode: "numeric",

                        pattern: "[0-9]{2}:[0-9]{2} [AaPp][Mm]",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </Box>
        )}
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              // inputFormat="MM/DD/YYYY"
              inputFormat="YYYY/MM/DD"
              format="YYYY/MM/DD"
              size="small"
              // label="Enter End Date "
              label={newDate?.endDate?newDate?.endDate:"Enter end date"}

              sx={{
                mt: 1.5,
                ml: "8px",
                width: "360px",
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                  height: "40px",
                },
                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",
                  color: "#999999",
                  fontSize: "14px",
                  lineHeight: "10px",
                },
              }}
              onChange={(e) => {
                handleDateChange("endDate", e);
              }}
              value={formData.endDate || null}
              error={!!errors.endDate}
              helperText={errors.endDate}
              renderInput={(params) => (
                <TextField size="small" {...params} placeholder="Event Name" />
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>
        {!showBox && (
          <Box sx={{ display: "flex", maxWidth: "368px" }}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  size="End Time"
                  // label="Enter end time"
                  label={newDate?.endTime?newDate?.endTime:"Enter end time"}

                  ampm={true}
                  format="h:mm A "
                  sx={{
                    width: "365px",
                    mt: 1.5,
                    ml: "8px",
                    "& .MuiInputBase-root": {
                      fontFamily: "MontserratRegular",

                      height: "40px",
                    },

                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                      fontFamily: "MontserratRegular",

                      color: "#999999",

                      fontSize: "14px",

                      lineHeight: "10px",
                    },
                  }}
                  value={formData.endTime || null}
                  onChange={(e) => {
                    handleTimeChange("endTime", e);
                  }}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </Box>
        )}
      </Box>

      {/* ............................end of left box */}

      {/* ........................start of right box */}

      <Box
        sx={{
          display: "flex",

          flexDirection: "column",

          maxWidth: "400px",
        }}
      >
        {/* ..........end date */}

        <FormControl
          sx={{ width: "360px", ml: 1, fontFamily: "MontserratRegular" }}
        >
          <InputLabel
            id="demo-simple-select-label"
            size="small"
            sx={{
              fontFamily: "MontserratRegular",

              color: "#999999",

              fontSize: "14px",
            }}
          >
            Category
          </InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.category}
            label="Category"
            size="small"
            onChange={(e) => {
              handleChange("category", e.target.value);
            }}
            error={!!errors.category}
            helperText={errors.category}
          >
            {eventCat?.map((item, index) => {
              return <MenuItem value={item.id}>{item.name}</MenuItem>;
            })}
          </Select>
        </FormControl>

       

        <FormControl
          sx={{
            width: "360px",

            mt: 2.4,

            marginLeft: 1,

            mb: 0,

            fontFamily: "Montserrat",
          }}
        >
          <InputLabel
            id="demo-simple-select-label"
            size="small"
            sx={{
              fontFamily: "MontserratRegular",

              color: "#999999",

              fontSize: "14px",
            }}
          >
            Type
          </InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.eventType}
            label="Event Type"
            size="small"
            onChange={(e) => {
              handleChange("eventType", e.target.value);
              setShowRadioGroup([4, 1, 3].includes(e.target.value));
            }}
            error={!!errors.eventType}
            helperText={errors.eventType}
          >
            {eventType?.map((item, index) => {
              return <MenuItem value={item.id}>{item.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        {showRadioGroup && (
        <FormControl
        sx={{width:"550px",mt:1, display: "flex", flexDirection: "row", alignItems: "center" }}
      >
      
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={vcMode}
          onChange={(e) => setVcMode(e.target.value === "true")}
          sx={{ width:"400px", margin: 1,"& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
          { color: "#FF0000" }, }}
        >
          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "FF0000",
              },
              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",
                color: "#999999",
                fontSize: 14,
              },
            }}
            value="true"
            control={<Radio />}
            label="ConveneVC"
          />
          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "FF0000",
              },
              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",
                color: "#999999",
                fontSize: 14,
              },
            }}
            value="false"
            control={<Radio />}
            label="Other VC"
          />
        </RadioGroup>
      </FormControl>
        )}
        
        {showRadioGroup && (
        <FormControl
        sx={{width:"550px",mt:1, display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography sx={{ fontFamily: "MontserratRegular", ml: 1, mr: 0 }}>
          Do you want to Record this event?
        </Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={eventMode}
          onChange={(e) => setEventMode(e.target.value === "true")}
          sx={{ width:"400px", margin: 1,"& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
          { color: "#FF0000" }, }}
        >
          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "FF0000",
              },
              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",
                color: "#999999",
                fontSize: 14,
              },
            }}
            value="true"
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "FF0000",
              },
              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",
                color: "#999999",
                fontSize: 14,
              },
            }}
            value="false"
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
        )}

<TextField
          sx={{
            width: "360px",
            mt: 2,
            marginLeft: 1,
            mb: 1,
            fontFamily: "Montserrat",
            "& .MuiInputBase-root": {
              fontFamily: "MontserratRegular",
            },
            "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              fontFamily: "MontserratRegular",
              color: " #999999",
              fontSize: "14px",
              lineHeight: " 20px",
            },
          }}
          required
          size="small"
          value={formData.location}
          onChange={(e) => {
            handleChange("location", e.target.value);
          }}
          error={!!errors.location}
          helperText={errors.location}
          id="standard-required"
          label="Location"
        />

        {errorLocation ? (
          <Typography sx={{ color: "red", mx: 2 }}>
            Please Enter Location
          </Typography>
        ) : (
          ""
        )}

        <Box sx={{ marginTop: "0px" }}>
          <TextField
            id="outlined-multiline-static"
            label="Description"
            multiline
            size="small"
            rows={3.4}
            sx={{
              width: "360px",

              mt: 2,

              marginLeft: 1,

              mb: 1,

              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
              },

              "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                fontFamily: "MontserratRegular",

                color: " #999999",

                fontSize: "14px",

                lineHeight: " 20px",
              },
            }}
            value={formData.description}
            onChange={(e) => {
              handleChange("description", e.target.value);
            }}
            error={!!errors.description}
            helperText={errors.description}
            // id="standard-required"
            //   defaultValue="Default Value"
          />

          {errorDescription && (
            <Typography sx={{ color: "red" }}>
              Please Enter Description
            </Typography>
          )}
        </Box>
      </Box>

      {/* ......................end of right box */}

      {/* .....................................recurring event starts..................................... */}

      <FormControl
        sx={{
          display: "flex",

          flexDirection: "row",

          alignItems: "center",

          width: "100%",
        }}
      >
        <Typography sx={{ fontFamily: "MontserratRegular", ml: 1, mr: 2 }}>
          Recurring Event
        </Typography>

        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={event}
          onChange={handleEvent}
          // value={data.gender}

          // onChange={(e) => {

          //   handleChange("gender", e.target.value);

          // }}

          sx={{
            margin: 1,
            "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
              color: "#FF0000",
            },
          }}
        >
          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "#FF0000",
              },

              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",

                color: "#999999",

                fontSize: 14,
              },
            }}
            value="yes"
            control={<Radio />}
            label="Yes"
          />

          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "FF0000",
              },

              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",

                color: "#999999",

                fontSize: 14,
              },
            }}
            value="no"
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </FormControl>

      {showBox && (
        <>
          <FormControl
            sx={{ width: "360px", m: 1, fontFamily: "MontserratRegular" }}
          >
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              sx={{
                fontFamily: "MontserratRegular",

                color: "#999999",

                fontSize: "14px",
              }}
            >
              Recurrence Frequency
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.frequency}
              label="Recurrence Frequency"
              size="small"
              onChange={(e) => {
                handleChange("frequency", e.target.value);
              }}
            >
              {frequency.map((item, index) => {
                return <MenuItem value={item.name}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <Box
            sx={{
              display: "flex",

              flexDirection: "row",

              maxWidth: "800px",

              flexWrap: "wrap",

              justifyContent: "space-between",

              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
              },
            }}
          >
            <Box sx={{ display: "flex", maxWidth: "360px", mx: 1 }}>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    size="Start Time"
                    label="Recurrence start time"
                    ap={true}
                    format="h:mm A "
                    sx={{
                      width: "360px",

                      "& .MuiInputBase-root": {
                        fontFamily: "MontserratRegular",

                        height: "40px",
                      },

                      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                        fontFamily: "MontserratRegular",

                        color: "#999999",

                        fontSize: "14px",

                        lineHeight: "10px",
                      },
                    }}
                    value={formData.startTime}
                    onChange={(e) => {
                      handleTimeChange("startTime", e);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        placeholder="Event Name"
                        inputProps={{
                          inputMode: "numeric",

                          pattern: "[0-9]{2}:[0-9]{2} [AaPp][Mm]",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </Box>

            <Box sx={{ display: "flex", maxWidth: "360px" }}>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    size="End Time"
                    label="Recurrence end time"
                    ampm={true}
                    format="h:mm A "
                    sx={{
                      width: "360px",

                      "& .MuiInputBase-root": {
                        fontFamily: "MontserratRegular",

                        height: "40px",
                      },

                      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                        fontFamily: "MontserratRegular",

                        color: "#999999",

                        fontSize: "14px",

                        lineHeight: "10px",
                      },
                    }}
                    value={formData.endTime}
                    onChange={(e) => {
                      handleTimeChange("endTime", e);
                    }}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </Box>
          </Box>
        </>
      )}

      {/* .....................................recurring event ends..................................... */}

      <Box
        sx={{
          display: "flex",

          width: "94%",

          maxWidth: "800px",

          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",

            width: 360,

            minWidth: 360,

            justifyContent: "center",

            alignItems: "center",

            flexWrap: "wrap",
          }}
        >
          <Button
            onClick={addData}
            variant="contained"
            sx={{
              ml: 28,
              marginTop: 5,
              width: "100px",

              color: "#FFFFFF",

              backgroundColor: "#FF0000",

              "&:hover": {
                backgroundColor: "#FF0000",
              },

              height: 30,
            }}

            // sx={[styles.btn, styles.textField]}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
