import { Box, Typography, Button } from "@mui/material";
import { React, useState, useEffect } from "react";
import Navbar from "../Navbar";
import AttendanceTable from "./AttendanceTable";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useNhostClient } from "@nhost/react";
import {
  useAccessToken,
  useFileUpload,
  useFileUploadItem,
  useMultipleFilesUpload,
  id,
  useSignOut,
  useAuthenticationStatus,
} from "@nhost/react";


const GET_EVENTS_QUERY = gql`
  query GetAllEventInfo($id: bigint) {
    event_master(where: { id: { _eq: $id } }) {
      activation_date
      closure_date
      created_at
      cover_img_path
      deactivation_date
      description
      id
      location
      metadata
      name
      recurrance_end_date
      recurrance_frequency
      recurrance_start_date
      type_id
      updated_at
      event_category {
        created_by
        created_date
        description
        id
        name
      }
      event_faculties {
        event_id
        faculty_type_id
        id
        user_id
      }
      event_type {
        created_by
        created_date
        description
        id
        name
      }
    }
  }
`;

const INSERT_CONTENT = gql`
  mutation AddContent(
    $associated_event_id: bigint
    $associated_group_id: bigint
    $file_name: String = ""
    $link: String = ""
    $published_by: String = ""
    $type: String = ""
    $uploaded_by: String = ""
  ) {
    insert_content_master(
      objects: {
        associated_event_id: $associated_event_id
        associated_group_id: $associated_group_id
        file_name: $file_name
        link: $link
        published_by: $published_by
        type: $type
        uploaded_by: $uploaded_by
      }
    ) {
      returning {
        associated_event_id
        associated_group_id
        category_id
        expiry_date
        file_name
        id
        link
        published_by
        publishing_date
        type
        upload_date
        uploaded_by
      }
    }
  }
`;



export default function AttendanceReport(props) {
  const navigate = useNavigate();
  const nhost = useNhostClient();

  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id: cover_img_path,
    bucketId,
    name,
  } = useFileUpload();


  const location = useLocation();
  const attendanceData = location.state?.attendanceData;

  // ................ fetch event data start................................................................
  const [Events, setEvents] = useState([]);
  const { loading, error, data } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      id: location.state?.attendanceData.id,
    },
  });


  // console.log('PDF file uploaded successfully with ID:', cover_img_path);
  const [docName, setDocName] = useState("");

  const generateAndDownloadPDF = async () => {
    const pdf = new jsPDF();
    const body = document.body;
    const fileName = `${data?.event_master[0].name}_Attendance_Report.pdf`;
  
    html2canvas(body).then(async (canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  
      // Generate a blob from the PDF content
      const blob = pdf.output('blob');
  
      // Create a File object from the blob
      const pdfFile = new File([blob], fileName, { type: 'application/pdf' });
      // console.log("file name uuuuuuuuuuu",fileName);
      setDocName(fileName);
      // console.log("file name ", fileName);
      // console.log("ko");
      // Create a FormData and append the PDF file
      const formData = new FormData();
      formData.append('file', pdfFile);
  
      // Upload the PDF file to Nhost storage
      try {
        nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);
        const uploadResponse = await upload({ file: pdfFile }); // Replace with your upload function
        console.log("upload response",uploadResponse);
        if(uploadResponse.isUploaded){
          handleContentManagement(uploadResponse.id);
          // console.log("id",cover_img_path);

        }

        // const pdfFileId = uploadResponse.fileMetadata.id;
        // console.log('PDF file uploaded successfully with ID:', cover_img_path);
  
        // Now you have the PDF file ID, you can use it as needed (e.g., update your database with the ID)
        // Make sure to handle any errors that may occur during the upload process.
      } catch (error) {
        console.error('Error uploading PDF file:', error);
      }
    });
  };


  useEffect(() => {
    setEvents(data?.event_master[0]);
    // console.log("Event inside", data?.event_master[0]);
  }, [data?.event_master[0]]);
  // console.log("Events outside",Events);

  // ................ fetch event data end................................................................

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Pad single-digit months with leading zero
  const currentDay = String(currentDate.getDate()).padStart(2, "0"); // Pad single-digit days with leading zero
  const currentHours = String(currentDate.getHours()).padStart(2, "0");
  const currentMinutes = String(currentDate.getMinutes()).padStart(2, "0");
  const currentSeconds = String(currentDate.getSeconds()).padStart(2, "0");

  let amOrPm = "AM";
let formattedHours = currentHours;

if (currentHours >= 12) {
  amOrPm = "PM";
  formattedHours = currentHours % 12 || 12;
}

  const currentDateFormatted = `${currentYear}/${currentMonth}/${currentDay}`;
  const currentTimeFormatted = `${formattedHours}:${currentMinutes}:${currentSeconds} ${amOrPm}`;

  // console.log("Current Date:", currentDateFormatted);
  // console.log("Current Time:", currentTimeFormatted);

  let eventName = "Go Digital Stay Human";
  let groupName = "Inner Group";
  let eventCat = "Event Category 1";
  let date = "13/02/2023";
  let type = "Online";
  let time = "10:30 AM to 01:30 PM";

  const [openPopUp, setOpenPopUp] = useState(false);
  const open = () => {
    setOpenPopUp(!openPopUp);
  };

  
  // console.log("cover im",cover_img_path);
  const handleContentManagement = async (cover_img_path) => {
    // console.log("cover image path",cover_img_path);
    try {
      const { data: insertedData } = await insertuser({
        variables: {
          associated_event_id: Events?.id,
          link:cover_img_path,
          file_name: docName,
        },
      });
      console.log("instered group header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };
  
  const [insertuser] = useMutation(INSERT_CONTENT, {
    onCompleted: (data) => {
      console.log("last data updated");
      // refetch();
      console.log("uploaded data",data);
    },
    onError: (error) => {
      console.log("error is ", error);
    },
  });

  return (
    <Box>
      <Navbar />
      <Box sx={Styles.topContainer}>
        <Typography sx={Styles.largeTxt}>
          {Events?.name} {">"} Complete Class {">"} Attendance Report
        </Typography>
        <Box
          sx={{
            marginX: "5px",
            fontSize: "12px",
            backgroundColor: "white",
            color: "#252525",
            borderColor: "#252525",
          }}
        >
          <Button sx={Styles.btn} onClick={generateAndDownloadPDF}>
            <img
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
              src={require("../../images/download.png")}
            />{" "}
            SAVE TO CONTENTS
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box sx={Styles.upperBox}>
          <Box sx={[Styles.textBox, { marginLeft: "22px" }]}>
            <Typography sx={Styles.text1}>
              Event Name :{""}
              <Typography sx={Styles.text2}> {Events?.name}</Typography>
            </Typography>
            <Typography sx={Styles.text1}>
              Associated Group Name :{" "}
              <Typography sx={Styles.text2}> {groupName}</Typography>
            </Typography>
          </Box>
          <Box sx={Styles.textBox}>
            <Typography sx={Styles.text1}>
              Class Category :{" "}
              <Typography sx={Styles.text2}>
                {" "}
                {Events?.event_category?.name}
              </Typography>
            </Typography>
            <Typography sx={Styles.text1}>
              Class Start Date :{" "}
              <Typography sx={Styles.text2}> {Events?.recurrance_start_date}</Typography>
            </Typography>
          </Box>
          <Box sx={Styles.textBox}>
            <Typography sx={Styles.text1}>
              Class Type :{" "}
              <Typography sx={Styles.text2}>
                {" "}
                {Events?.event_type?.name}
              </Typography>
            </Typography>
            <Typography sx={Styles.text1}>
              Current Time : <Typography sx={Styles.text2}> {currentTimeFormatted}</Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "95%",
          margin: "auto",
          marginTop: "15px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <img style={Styles.info} src={require("../../images/info.png")} />
          <Typography sx={Styles.text2}>
            Click the checkbox mark attendance
          </Typography>
        </Box>
      </Box>
      <Box sx={Styles.tableBox}>
        <AttendanceTable
        id = {location.state?.attendanceData.id} />
      </Box>
      <Box
        sx={{
          width: "95%",
          margin: "auto",
          marginTop: "15px",
          display: "flex",
          justifyContent: "flex-end",
          marginY: "10px",
        }}
      >
        {/* <Box>
          <Button sx={Styles.btnRes}  >Save</Button>
          <Button sx={Styles.btnSave} onClick={() => navigate("/eventclosure")}>
            Submit
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
}
const Styles = {
  topContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
    "@media (max-width: 668px)": {
      width: "100%",
      display: "flex",
      fontSize: "14px",
    },
  },
  btn: {
    border: "1px solid #252525",
    boxShadow: "(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    height: "35px",
    marginRight: "25px",
    "&:hover": {
      backgroundColor: "#999999",
      color: "#ffffff",
    },
    "@media (max-width: 668px)": {
      right: 0,
      top: 104,
      position: "absolute",
      width: "130px",
      height: "25px",
    },
  },
  upperBox: {
    width: "95%",
    height: "80px",
    marginTop: "20px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: " 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    overflow: "hidden",
    flexWrap: "wrap",
    "@media (max-width: 668px)": {
      flexDirection: "column",
      overflow: "scroll",
    },
  },
  textBox: {
    width: "22.33%",
    flexDirection: "row",
    "@media (max-width: 668px)": {
      width: "70%",
      flexDirection: "row",
      marginTop: "20px",
    },
  },
  text1: {
    fontFamily: "MontserratSemiBold",
    color: "#252525",
    fontSize: "14px",
    marginBottom: "10px",
    display: "flex",
    "@media (max-width: 668px)": {
      fontSize: "12px",
    },
  },
  text2: {
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    "@media (max-width: 668px)": {
      fontSize: "12px",
    },
  },
  info: {
    width: "20px",
    height: "20px",
  },
  btnRes: {
    ml: 2,
    width: "100px",
    color: "FF0000",
    backgroundColor: "#ffffff",
    border: "1px solid #999999",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#999999",
      color: "#ffffff",
    },
    height: 30,
    fontFamily: "MontserratRegular",
  },
  btnSave: {
    ml: 2,
    width: "100px",
    color: "#FF0000",
    backgroundColor: "#ffffff",
    border: "1px solid #FF0000",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#FF0000",
      color: "#ffffff",
    },
    height: 30,
    fontFamily: "MontserratRegular",
  },
  tableBox: {
    width: "95%",
    margin: "auto",
    marginTop: "20px",
  },
};
