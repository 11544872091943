import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useMutation, gql } from "@apollo/client";

const INSERT_GROUP_CATEGORY = gql`
  mutation InsertGroupCategory($created_by: String!, $description: String!, $name: String!) {
    insert_group_category(objects: {created_by: $created_by, description: $description, name: $name}) {
      returning {
        name
        id
        description
        created_date
        created_by
      }
    }
  }
`;

const UPDATE_GROUP_CATEGORY = gql`
  mutation UpdateGroupCategory($id: bigint!, $name: String!, $description: String!, $created_by: String!) {
    update_group_category(where: {id: {_eq: $id}}, _set: {name: $name, description: $description, created_by: $created_by}) {
      returning {
        name
        id
        description
        created_date
        created_by
      }
    }
  }
`;

export default function AddGroupCategory(props) {
  const [open, setOpen] = React.useState(false);
  const [insertGroupCategory] = useMutation(INSERT_GROUP_CATEGORY);
  const [updateGroupCategory] = useMutation(UPDATE_GROUP_CATEGORY);

  const [data, setData] = React.useState(props);
  const [error, setError] = React.useState();

  const handleClickOpen = () => {
    props.setNewTaskOpen(true);
  };

  const handleClose = () => {
    props.setNewTaskOpen(false);
  };

  const handleSave = async () => {
    if (props.isforUpdate) {
      try {
        const { data: updateData } = await updateGroupCategory({
          variables: {
            id: props.id,
            description: data["description"],
            name: data["group_category"],
            created_by: localStorage.getItem("email"),
          },
        });
        console.log(updateData);
        props.setNewTaskOpen(false);
        props.setUpdated(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const { data: insertData } = await insertGroupCategory({
          variables: {
            description: data["description"],
            name: data["group_category"],
            created_by: localStorage.getItem("email"),
          },
        });
        console.log(insertData);
        props.seteventCategory((prevData) => {
          return [...prevData, insertData.insert_group_category.returning[0]];
        });
        props.setNewTaskOpen(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };

  const resetFields = () => {
    setData({
      group_category: "", // Reset group_category field
      description: "", // Reset description field
    });
    setError("");
  };

  React.useEffect(() => {
    if (!props.isforUpdate) {
      resetFields(); // Reset fields when opening the popup for adding a new group category
    }
  }, [props.isforUpdate]);

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <Box sx={{ backgroundColor: "#999999", height: "100%" }}>
          <Typography sx={{ m: 1, color: "#FFFFFF" }}>
            Add Group Category
          </Typography>
        </Box>
        <Box sx={{ mx: 3, my: 1 }}>
          <TextField
            id="outlined-textarea"
            size="small"
            placeholder='Group Category'
            multiline
            variant="outlined"
            value={data.group_category}
            onChange={(e) => {
              handleChange("group_category", e.target.value);
            }}
            sx={{ width: 360 }}
          />
        </Box>
        <Box sx={{ mx: 3, my: 1 }}>
          <TextField
            id="outlined-multiline-static"
            placeholder="Description"
            sx={{ width: 360 }}
            multiline
            rows={4}
            value={data.description}
            onChange={(e) => {
              handleChange("description", e.target.value);
            }}
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1 }} >
          <Button sx={{ border: 1, width: "100px", height: 30, mx: 1, borderColor: '#999999', color: '#999999' }} onClick={handleClose}>Cancel</Button>
          <Button sx={{
            ml: 2,
            width: "100px",
            color: "#FFFFFF",
            backgroundColor: "#005e89",
            '&:hover': {
              backgroundColor: "#005e89",
              color: "#FFFFFF"
            },
            height: 30,
          }}
            onClick={handleSave}
          >Save</Button>
        </Box>
      </Dialog>
    </div>
  );
}
