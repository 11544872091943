const Styles ={
    largeTxt: {
        fontFamily: "MontserratRegular",
        fontWeight: "500",
        fontSize: "20px",
        color: "#414042",
        margin: "auto 0px auto 4px",
        ml: "2%",
      },
      topContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        // minWidth: "336px",
        margin: "auto",
        height: "8vh",
        boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)"
      },
      mainGrid:{
        width: "95%",
        margin: "auto",
        marginTop: "25px",
      },
      lowerContainer:{
        width: "100%",
        justifyContent: "center",
        textAlign: "center",
        alignItems:"center",
      },
      grid1:{
        width: "770px",
        border: "1px solid #999999",
        borderRadius:"10px",
        marginRight: "10px",
        
      },
      grid:{
        width:"320px",
        border: "1px solid #999999",
        borderRadius:"10px",
        marginRight: "10px",
      },






     createfeed :{
        height: '8vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems:' center',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      },
      createfeedTxt: {
        fontWeight: 'bold',
        margin: 'auto 2% auto 2%',
        fontSize: '18px',
        color: '#414042',
        fontFamily: 'MontserratBold',
      },
      mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        justifyContent: 'center',
        flexWrap: 'wrap',
        color: '#252525',
        fontWeight: 'lighter',
        fontFamily: 'MontserratMedium',
      },
      feedDetails: {
        display: 'flex',
        flexDirection: 'column',
        height: '550px',
        width: '780px',
        margin: '1%',
        borderRadius: '10px',
        border: '1px solid #999999',
      },
      feedDetails1: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      },
      // @media (max-width: 767px) :{
      //  feedDetails1:{
      //     flex-direction: column,
      //     margin: auto,
      //   },
      //  feedDetails:{
      //     height: auto,
      //     width: 100%,
      //     display: flex,
      //     justify-content: center,
      //   },
      //  inputDiv:{
      //     width: 100%,
      //     margin: auto,
      //   },
      //  uploadedImageDiv:{
      //     margin: auto,
      //   },
      // },
      
     feedConfig :{
        height:' 550px',
        marginTop: '1%',
        width:' 320px',
        left: '847px',
        top: '173px',
        border:' 1px solid #999999',
        borderRadius: '10px',
      },
     feedHistory :{
        height: '550px',
        margin: '1%',
        width: '320px',
        border: '1px solid #999999',
        borderRadius: '10px',
        boxSizing: 'border-box',
        overflow: 'scroll',
      },
     feedDetailsHeader :{
        height: '30px',
        borderBottom: '1px solid #999999',
        fontFamily: "MontserratBold",
        fontSize: '16px',
        color:' #252525',
        paddingLeft: '20px',
        /* margin-left: 20px, */
      },
     inputDiv :{
        width: '50%',
        height:' 100%',
        paddingLeft: '25px',
      },
     headerInput :{
        fontFamily: "MontserratLite",
        height:' 35px',
        paddingLeft:' 15px',
        width: '340px',
        border: '1px solid #999999',
        borderRadius: '10px',
      },
     descriptionInput :{
      resize:"none",
        fontFamily: "MontserratLite",
        height: '308px',
        paddingLeft: '15px',
        paddingTop: '15px',
        width:' 340px',
        border: '1px solid #999999',
        borderRadius: '10px',
      },
     uploadImgDiv :{
        width: '360px',
        display: 'flex',
        flexWrap: 'wrap',
      },
     dateInput :{
        border: '1px solid #999999',
        fontFamily: "MontserratLite",
        height: '40px',
        width: '250px',
        paddingLeft: '15px',
        left:' 0px',
        top:' 0px',
        borderRadius: '10px',
        margin: '5%',
      },
     dropdown :{
        fontFamily: "MontserratLite",
        height: '40px',
        width: '270px',
        paddingLeft: '15px',
        left:' 0px',
        top:' 0px',
        border: '1px solid #999999',
        borderRadius: '10px',
        margin: '5%',
      },
     uploadInput :{
        border: '1px solid #005e89',
        fontFamily: "MontserratRegular",
        height:' 35px',
        width: '170px',
        backgroundColor: '#005e89',
        color: '#ffffff',
        paddingLeft:' 15px',
        borderRadius: '10px',
        margin: '5%',
        top: 20,
      },
    //  uploadInput::placeholder :{
    //     color: #ffffff,
    //   },
     uploadedImageDiv :{
        marginTop: '10px',
        width:' 100%',
        height:' 100%',
        display: 'flex',
        justifyContent: 'center',
      },
     uploadedImage :{
        width: '300px',
        height: '300px',
        
        /* position:relative; */
      },
      






}
export default Styles;