// import React from "react";
// import {
//   Box,
//   TextField,
//   Button,
//   Radio,
//   FormControlLabel,
//   Typography,
// } from "@mui/material";
// import { ThemeProvider } from "@mui/material/styles";
// import { createTheme } from "@mui/material/styles";

// import back from "../../src/images/Space.png";

// const theme = createTheme({
//   typography: {
//     fontFamily: "MontserratRegular",
//     marginLeft: "4px",
//     color: "#999999",
//     fontSize: "10px",
//     fontWeight: "bold",
//   },
// });

// export default function UserSignup() {
//   return (
//     <ThemeProvider theme={theme}>
//       <Box sx={styles.background}>
//         {/* ............................form starts here.............................. */}
//         <Box sx={styles.form}>
//           <Box sx={{ display: "flex", flexDirection: "column" }}>
//             <Typography variant="h6" sx={styles.signup}>
//               Sign up
//             </Typography>

//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "row",
//                 "@media (max-width:768px)": {
//                   flexWrap: "wrap",
//                 },
//               }}
//             >
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "row",
//                   width: "50%",
//                   "@media (max-width:768px)": {
//                     flexWrap: "wrap",
//                   },
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     ml: "40px",
//                     minWidth: "320px",
//                     "@media (max-width:768px)": {
//                       margin: "10px",
//                       ml: "0px",
//                       justifyContent: "center",
//                     },
//                   }}
//                 >
//                   <TextField
//                     id="outlined-basic"
//                     label="Name"
//                     variant="outlined"
//                     sx={styles.leftTextbox}
//                     size="small"
//                   />
//                   <TextField
//                     id="outlined-basic"
//                     label="Email ID"
//                     variant="outlined"
//                     sx={styles.leftTextbox}
//                     size="small"
//                   />
//                   <TextField
//                     id="outlined-basic"
//                     label="Address line 1"
//                     variant="outlined"
//                     sx={styles.leftTextbox}
//                     size="small"
//                   />
//                   <TextField
//                     id="outlined-basic"
//                     label="City"
//                     variant="outlined"
//                     sx={styles.leftTextbox}
//                     size="small"
//                   />
//                   <TextField
//                     id="outlined-basic"
//                     label="State/Province"
//                     variant="outlined"
//                     sx={styles.leftTextbox}
//                     size="small"
//                   />
//                 </Box>
//               </Box>

//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   width: "50%",
//                   minWidth: "320px",
//                   "@media (max-width:768px)": {
//                     margin: "10px",
//                     justifyContent: "center",
//                     justifySelf: "center",
//                   },
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "row",
//                     width: "500px",
//                     my: 1.5,
//                     mt: 1,
//                   }}
//                 >
//                   <Box sx={{ mt: 1, mr: 6 }}>
//                     <Typography>Gender</Typography>
//                   </Box>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-around",

//                       width: "300px",
//                     }}
//                   >
//                     <FormControlLabel
//                       value="female"
//                       control={<Radio />}
//                       label="Male"
//                     />
//                     <FormControlLabel
//                       value="male"
//                       control={<Radio />}
//                       label="Female"
//                     />
//                   </Box>
//                 </Box>

//                 <Box>
//                   <TextField
//                     id="outlined-basic"
//                     label="Mobile Number"
//                     variant="outlined"
//                     sx={styles.rightTextbox}
//                     size="small"
//                   />
//                   <TextField
//                     id="outlined-basic"
//                     label="Address line 2"
//                     variant="outlined"
//                     sx={styles.rightTextbox}
//                     size="small"
//                   />
//                   <TextField
//                     id="outlined-basic"
//                     label="Postal Code"
//                     variant="outlined"
//                     sx={styles.rightTextbox}
//                     size="small"
//                   />
//                   <TextField
//                     id="outlined-basic"
//                     label="Country"
//                     variant="outlined"
//                     sx={styles.rightTextbox}
//                     size="small"
//                   />
//                   <Button
//                     variant="contained"
//                     sx={styles.button}
//                   >
//                     Next
//                   </Button>
//                 </Box>
//               </Box>
//             </Box>

//             {/* ............................form ends here.............................. */}
//           </Box>
//         </Box>
//       </Box>
//     </ThemeProvider>
//   );
// }

// const styles = {
//   background: {
//     height: "100vh",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundImage: `url(${back})`,
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "center",
//     "@media (max-width:768px)": {
//       height: "100vh",
//       width: "90%",
//       justifyContent: "center",
//       margin: "auto",
//     },
//   },
//   form: {
//     display: "flex",
//     width: "80%",
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: "white",
//     margin: "auto",
//     "@media (max-with:768px)": {
//       width: "100%",
//       margin: "10px",
//     },
//   },
//   signup: {
//     color: "#414042",
//     display: "flex",
//     alignItems: "left",
//     fontFamily: "MontserratRegular",
//     fontSize: "20px",
//     fontWeight: "bold",
//     ml: "40px",
//     mb: "40px",
//     mt: "30px",
//   },
//   leftTextbox:
//   {
//     width: 400,
//     height: 45,
//     my: 1,
//     mx: 2,
//   },
//   rightTextbox:
//   {
//     width: 400,
//     height: 45,
//     my: 1,
//   },
//   button:{
//     display: "flex",
//     color: "white",
//     backgroundColor: "red",
//     width: "40%",
//     minWidth: "400px",
//     marginY:'6px',
//   }
// };

import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { React, useEffect, useState } from "react";
//  import bgvideo from "../../Login/awakevideo.mp4.mp4";

 import bgvideo from "../components/Login/awakevideo.mp4.mp4";
// import Navbar from "../Navbar";
// import { useNavigate } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import space from  "../images/Space.png"
// ../../components/AdminFeed/FullRedButton
import FullRedButton from "../../src/components/AdminFeed/FullRedButton";
// import { useDispatch } from "react-redux";
// import { addUser } from "../../Redux/features/UserSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSignUpEmailPassword, useUserData } from "@nhost/react";
import uploadimg from "../images/uploadImg.png";
const logo = require("../images/AWAKEORIGINS.png");


export default function AddUser() {
  const [image, setImage] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  // const [validation, setActiveStep] = useState(process.env.VALIDATION);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    // console.log(event.target.checked)
  };

  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      setImage(URL.createObjectURL(event.target.files[0]));
    };
    input.click();
  };
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const addUserToProfile = async (id, displayName) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "x-hasura-admin-secret",
      process.env.REACT_APP_NHOST_SECREATEKEY
    );
    myHeaders.append("Access-Control-Allow-Credentials", true);
    myHeaders.append("Access-Control-Allow-Origin", "*");

    myHeaders.append(
      "Access-Control-Allow-Methods",
      "GET,OPTIONS,PATCH,DELETE,POST,PUT"
    );
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
    );

    // console.log(data['description'])

    var graphql = JSON.stringify({
      query: `mutation AddUserToProfile($user_id: uuid, $displayName: String,$birth_date: date) {
        insert_profile_details(objects: {user_id: $user_id, first_name: $displayName, birth_date: $birth_date}) {
          returning {
            user_id
          }
        }
      }`,
      variables: {
        user_id: id,
        displayName: displayName,
        birth_date: data["birthDate"],
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
      // mode: "no-cors",
    };

    const res = await fetch(process.env.REACT_APP_URL, requestOptions);
    const data1 = await res.json();
    console.log("OOO : ", data1);
    profileUpdates(id);
  };

  const profileUpdates = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "x-hasura-admin-secret",
      process.env.REACT_APP_NHOST_SECREATEKEY
    );
    myHeaders.append("Access-Control-Allow-Credentials", true);
    myHeaders.append("Access-Control-Allow-Origin", "*");

    myHeaders.append(
      "Access-Control-Allow-Methods",
      "GET,OPTIONS,PATCH,DELETE,POST,PUT"
    );
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
    );

    // console.log(data['description'])

    var graphql = JSON.stringify({
      query: `mutation UpdateUserProfile($user_id: uuid, $bio: String, $birth_date: date, $category: String , $first_name: String , $last_name: String,$address1:String,$address2: String $profile_pic_path: String, $second_name: String) {
        update_profile_details(where: {user_id: {_eq: $user_id}}, _set: {bio: $bio, birth_date: $birth_date, category: $category, first_name: $first_name, last_name: $last_name, profile_pic_path: $profile_pic_path, second_name: $second_name,address2:$address2,address1: $address1}) {
          returning {
            user_id
            second_name
            last_name
            first_name
            category
            birth_date
            bio
            address1
            address2
          }
        }
      }`,
      variables: {
        user_id: id,
        first_name: data["displayName"],
        birth_date: data["birthDate"],
        bio: data["bio"],
        address1: data["add1"],
        address2: data["add2"],
        category: "unapproved",
        // profile_pic_path: image,
        // second_name: data["second_name"],
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
      // mode: "no-cors",
    };

    const res = await fetch(process.env.REACT_APP_URL, requestOptions);
    const data1 = await res.json();
    // console.log("sds", data1);
    navigate("/")
  };



  // signup starts
  const signup = async () => {
    if (data.email) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "x-hasura-admin-secret",
        process.env.REACT_APP_NHOST_SECREATEKEY
      );
  
      var graphql = JSON.stringify({
        query: `query CheckEmail($email: citext = "") {
          users(where: {email: {_eq: $email}}) {
            id
            email
          }
        }`,
        variables: {
          email: data["email"],
        },
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: graphql,
        redirect: "follow",
      };
  
      const res = await fetch(process.env.REACT_APP_URL, requestOptions);
      const data1 = await res.json();
      // console.log(data1);
  
      if (data1.data.users.length != 0) {
        
        alert("User already exist");
      } else {
        
        var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "x-hasura-admin-secret",
      process.env.REACT_APP_NHOST_SECREATEKEY
    );
    myHeaders.append("Access-Control-Allow-Credentials", true);
    myHeaders.append("Access-Control-Allow-Origin", "*");

    myHeaders.append(
      "Access-Control-Allow-Methods",
      "GET,OPTIONS,PATCH,DELETE,POST,PUT"
    );
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
    );

    // console.log("Hi : ", data["default_feed"]);

    var graphql = JSON.stringify({
      query: `mutation CreateNewUser($defaultRole: String,$passwordHash: String="awake12345" , $displayName: String , $email: citext, $phoneNumber: String, $locale: String) {
        insertUsers(objects: {defaultRole: $defaultRole,passwordHash:$passwordHash , displayName: $displayName, email: $email, phoneNumber: $phoneNumber, locale: $locale}) {
          returning {
            defaultRole
            email
            displayName
            id
            locale
            passwordHash
            phoneNumber
          }
        }
      }`,
      variables: {
        displayName: data["name"],
        email: data["email"],
        phoneNumber: data["phone"],
        defaultRole:"user",
        locale: "en",
        // cover_img_path:data['cover_img_path']
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
      // mode: "no-cors",
    };

    const res = await fetch(process.env.REACT_APP_URL, requestOptions);
    const data1 = await res.json();
    // console.log("jdsjkd", data1);

    const user_id = data1.data.insertUsers.returning[0].id;

    addUserToProfile(user_id, data1.data.insertUsers.returning[0].displayName);
      }
    }
    // else {
    //   alert("User already exist");
    // }
      };
  //signup end

  const userUpdate = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "x-hasura-admin-secret",
      process.env.REACT_APP_NHOST_SECREATEKEY
    );
    myHeaders.append("Access-Control-Allow-Credentials", true);
    myHeaders.append("Access-Control-Allow-Origin", "*");

    myHeaders.append(
      "Access-Control-Allow-Methods",
      "GET,OPTIONS,PATCH,DELETE,POST,PUT"
    );
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
    );

    console.log("Hi : ", data["default_feed"]);

    var graphql = JSON.stringify({
      query: `mutation AddUsers($displayName: String = "", $email: citext = "", $passwordHash: String, $phoneNumber: String = "") {
        insertUsers(objects: {displayName: $displayName, email: $email, passwordHash: $passwordHash, phoneNumber: $phoneNumber, defaultRole: "user"}) {
          returning {
            defaultRole
            displayName
            email
            id
            phoneNumber
          }
        }
      }`,
      variables: {
        displayName: data["name"],
        email: data["email"],
        phoneNumber: data["phone"],
        passwordHash:"awake12345"
        // cover_img_path:data['cover_img_path']
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
      // mode: "no-cors",
    };

    const res = await fetch(process.env.REACT_APP_URL, requestOptions);
    const data1 = await res.json();
    // console.log("good", data1);
  };

  const handleNav = async () => {
    signup();

   
    const newErrors = {};

    // Validate name
    if (!data.displayName) {
      newErrors.displayName = "Name is required";
    }

    // Validate email
    if (!data.email) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      newErrors.email = "Invalid email format";
    }

  

    if (!data.city) {
      newErrors.city = "City Name is required";
    }

    if (!data.country) {
      newErrors.country = "Country Name is required";
    }

    if (!data.state) {
      newErrors.state = "State Name is required";
    }

    // Validate pincode
    if (!data.pincode) {
      newErrors.pincode = "Pincode is required";
    }
    // } else if (!isValidPincode(data.pincode)) {
    //   newErrors.pincode = "Invalid pincode format";
    // }

    setErrors(newErrors);

    // Submit the form if there are no errors
    // if (Object.keys(newErrors).length === 0) {
    //   dispatch(
    //         addUser(userData)
    //       );
    // navigate("/usermanagement");
    //   // submit the form
    // }
  };
  const [date, setDate] = useState(new Date());
  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD HH:mm:ss");
    // console.log(formattedDate);
    setDate((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });

    handleChange("birthDate", formattedDate);
  };

  const [data, setData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    pincode: "",
    city: "",
    country: "",
    state: "",
    add1: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (key, value) => {
    // console.log(key, value);
    // if (key === "email" && !validateEmail(value)) {
    //   alert("Please enter a valid email");
    //   return;
    // }
    setData({
      ...data,
      [key]: value,
    });
  };

  function isValidEmail(email) {
    // You can use your own validation function or a library like validator.js
    // Here is a simple example:
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  // function isValidPincode(pincode) {
  //   const regex = /^[1-9]\d{5}$/; // Matches 6 digit pincode starting with a non-zero digit
  //   return regex.test(pincode);
  // }
  function isValidMobileNumber(mobileNumber) {
    // const regex = /^[6-9]\d{9}$/; // Matches 10 digit mobile number starting with 6, 7, 8 or 9
    // return regex.test(mobileNumber);
  }

  return (
    <Box sx={{ height: "100%", overflowX: "hidden" }}>

      <Box sx={{display:'flex',justifyContent:'center'}}>
      <img
      src={logo}
      alt="logo"
      style={{ width:100, height:100, marginTop: "25px" }}
    />
    </Box>
      <Box sx={Styles.mainBox}>
        <Box sx={Styles.firstBox}>
         
          <Box sx={Styles.secondBox}>
            <Box sx={Styles.inner1}>
              <Typography sx={Styles.headerText}>Personal Details</Typography>
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Name"
                required
                variant="outlined"
                value={data.displayName}
                onChange={(e) => {
                  handleChange("displayName", e.target.value);
                }}
                error={!!errors.displayName}
                helperText={errors.displayName}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  // inputFormat="MM/DD/YYYY"
                  size="small"
                  label="Birth Date"
                  sx={[
                    Styles.textField,
                    {
                      // width: "360px",
                      "& .MuiInputBase-root": {
                        fontFamily: "MontserratRegular",
                        height: "40px",
                        color: "999999"
                      },
                      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                        fontFamily: "MontserratRegular",
                        color: "#999999",
                        fontSize: "14px",
                        lineHeight: "10px",
                      },
                    },
                  ]}
                  value={date.birthDate}
                  onChange={(e) => {
                    handleDateChange("birthDate", e);
                  }}
                />
              </LocalizationProvider>
              <TextField
                id="filled-multiline-static"
                placeholder="Bio"
                multiline
                rows={3}
                sx={Styles.textField}
                value={data.bio}
                onChange={(e) => {
                  handleChange("bio", e.target.value);
                }}
              />

              {/* <Typography sx={[Styles.headerText, { marginTop: "0px" }]}>
                Configure User
              </Typography> */}
              {/* <FormControl sx={Styles.textField}>
                <InputLabel
                  id="demo-simple-select-label"
                  size="small"
                  InputLabelProps={{
                    sx: {
                      fontFamily: "MontserratRegular",
                    },
                  }}
                >
                  Member Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={data.category}
                  label="Member Category"
                  size="small"
                  // multiple
                  value={data.memberCategory}
                  onChange={(e) => {
                    handleChange("memberCategory", e.target.value);
                  }}
                >
                  {["Lead", "Prospect", "Suspect", "Member","unapproved"].map(
                    (item, index) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    }
                  )}
                </Select>
              </FormControl> */}

              <Typography sx={Styles.headerText}>Address Details</Typography>
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Address Line 1"
                variant="outlined"
                value={data.add1}
                onChange={(e) => {
                  handleChange("add1", e.target.value);
                }}
              />
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Postal Code"
                variant="outlined"
                value={data.pincode}
                onChange={(e) => {
                  handleChange("pincode", e.target.value);
                }}
                error={!!errors.pincode}
                helperText={errors.pincode}
              />
            </Box>

            <Box sx={Styles.inner2}>
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Email ID"
                required
                variant="outlined"
                value={data.email}
                onChange={(e) => {
                  handleChange("email", e.target.value);
                }}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Mobile Number"
                type="number"
                required
                variant="outlined"
                value={data.phone}
                onChange={(e) => {
                  handleChange("phone", e.target.value);
                }}
                //         error={!!errors.mobileNumber}
                // helperText={errors.mobileNumber}
              />
              <RadioGroup
                row
                // aria-labelledby="demo-row-radio-buttons-group-label"
                sx={{}}
                name="row-radio-buttons-group"
                value={data.gender}
                onChange={(e) => {
                  handleChange("gender", e.target.value);
                }}
              >
                <Typography sx={Styles.gender}>Gender</Typography>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#005e89" },
                  }}
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#005e89" },
                  }}
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#005e89" },
                  }}
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
              {/* <FormControl sx={[Styles.textField, { marginTop: "100px" }]}>
                <InputLabel
                  id="demo-simple-select-label"
                  size="small"
                  InputLabelProps={{
                    sx: {
                      fontFamily: "MontserratRegular",
                    },
                  }}
                >
                  Assign Group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.assignGroup}
                  label="Assign Group"
                  size="small"
                  onChange={(e) => {
                    handleChange("assignGroup", e.target.value);
                  }}
                >
                  {["Group", "Stand Alone", "Free"].map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl> */}
              <TextField
                size="small"
                sx={[Styles.textField, { marginTop: "115px" }]}
                id="outlined-basic"
                placeholder="Address Line 2"
                variant="outlined"
                value={data.add2}
                onChange={(e) => {
                  handleChange("add2", e.target.value);
                }}
              />
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="State/ Province "
                variant="outlined"
                value={data.state}
                onChange={(e) => {
                  handleChange("state", e.target.value);
                }}
                error={!!errors.state}
                helperText={errors.state}
              />
            </Box>

            <Box sx={Styles.inner2}>
              <Box style={Styles.uploadPhoto}>
                <Typography sx={Styles.uploadText}>Upload Photo</Typography>
                {image ? (
                  <Box sx={Styles.uploaded}>
                    <img
                      style={Styles.uploadLogo}
                      src={uploadimg}
                      alt="uploaded image"
                    />
                  </Box>
                ) : (
                  <img
                    style={Styles.uploadLogo}
                    src={require("../images/uploadImg.png")}
                    alt="img"
                    onClick={handleImageClick}
                  />
                )}
              </Box>
              {/* <FormControlLabel
                // value={}
                sx={{
                  marginTop: "115px",
                  marginLeft: "10px",
                  fontFamily: "MontserratRegular",
                  "& .MuiFormControlLabel-label": {
                    fontFamily: "MontserratRegular",
                  },
                  "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked":
                    { color: "#005e89" },
                }}
                control={<Checkbox defaultChecked onChange={handleCheckboxChange}   />}
                label="Click the box to make a staff user."
              /> */}
              <TextField
                size="small"
                sx={[Styles.textField, { marginTop: "125px" }]}
                id="outlined-basic"
                placeholder="city"
                required
                variant="outlined"
                value={data.city}
                onChange={(e) => {
                  handleChange("city", e.target.value);
                }}
                error={!!errors.city}
                helperText={errors.city}
              />
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Country "
                variant="outlined"
                value={data.country}
                onChange={(e) => {
                  handleChange("country", e.target.value);
                }}
                error={!!errors.country}
                helperText={errors.country}
              />
              <Box
                sx={{ width: "90%", marginLeft: "15px", marginBottom: "20px" }}
              >
                <FullRedButton click={handleNav} title="Submit" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
const Styles = {
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // minWidth: "336px",
    margin: "auto",
    height: "8vh",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  mainBox: {
    width: "100%",
    height: "530px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      height: "0.4em",
  
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    "@media (max-width:768px)": {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  firstBox: {
    marginTop: "25px",
    width: "95%",
    // height: "570px",
    border: "1px solid #999999",
    borderRadius: "10px",
    "@media (max-width:768px)": {
      width: "90%",
      height: "100%",
      margin: "auto",
    },
  },
  headerBox: {
    width: "100%",
    height: "25px",
    borderBottom: "1px solid #999999",
    alignItems: "center",
    // padding:"auto"
  },
  headerText: {
    marginTop: "5px",
    margin: "15px",
    // marginLeft:"15px",
    padding: "auto",
    alignItems: "center",
    fontSize: "14px",
    color: "#252525",
    fontWeight: "bold",
    fontFamily: "MontserratRegular",
  },
  secondBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  inner1: {
    width: "33.33%",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  inner2: {
    marginTop: "51px",
    width: "33.33%",
    // height:"50px"
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  textField: {
    width: "90%",
    marginLeft: "15px",
    marginBottom: "15px",
    "& .MuiInputBase-root": {
      fontFamily: "MontserratRegular",
    },
  },
  gender: {
    fontSize: "16px",
    fontFamily: "MontserratMedium",
    // fontWeight: "bold",
    margin: "15px",
    marginLeft: "25px",
    marginRight: "55px",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  uploadPhoto: {
    width: "95%",
    height: "150px",
    border: "1px solid #999999",
    borderRadius: "10px",
    margin: "auto",
    // display:"flex",
    // justifyContent:"center",
    "@media (max-width:768px)": {
      margin: "auto",
    },
  },
  uploadText: {
    margin: "10px",
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    color: "#999999",
  },
  uploadLogo: {
    width: "80px",
    marginLeft: "40%",
    cursor: "pointer",
  },
  uploaded: {
    width: "100%",
    bottom: "0px",
  },
};
