import {
  Box,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { React, useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_GROUP_TYPE = gql`
  query GetGroupType {
    group_type {
      created_by
      created_date
      description
      id
      name
    }
  }
`;
const GET_GROUP_CATEGORY = gql`
  query GetGroupCategory {
    group_category {
      created_by
      created_date
      description
      id
      name
    }
  }
`;
const INSERT_GROUP_HEADER_INFO_MUTATION = gql`
  mutation InsertGroupHeaderInfo(
    $category_id: bigint
    $decription: String
    $type: bigint
    $name: String
    $activation_date: date
    $deactivation_date: date
    $level_up_group: bigint
    $price: String
    $duration: String
    $status: String
  ) {
    insert_group_master(
      objects: {
        category_id: $category_id
        decription: $decription
        type: $type
        name: $name
        activation_date: $activation_date
        deactivation_date: $deactivation_date
        level_up_group: $level_up_group
        price: $price
        duration: $duration
        status: $status
      }
    ) {
      returning {
        activation_date
        category_id
        deactivation_date
        decription
        id
        level_up_group
        name
        type
        price
        duration
      }
    }
  }
`;

const INSERT_GROUP_CHAT_NOTIFICATION_MUTATION = gql`
  mutation insert_chat_notification($group_id: bigint , $sender_id: uuid = "") {
    insert_group_chatn(objects: {group_id: $group_id, notification: 1, sender_id: $sender_id}) {
      returning {
        group_id
        id
        notification
      }
    }
  }
`;


export default function HeaderInformationGM(props) {
  const setNewData = props?.setData;
  const newData = props?.data;
  const setNewDate = props?.setDate;
  const newDate = props?.date;
  const newDuration = newDate?.duration;
  console.log("this is new props data",newDuration);
  const [data, setdata] = useState(newData);
  const handleChange = (key, value) => {
    console.log(key, value);
    setdata({
      ...data,
      [key]: value,
    });
  };
  const [date, setDate] = useState(new Date(newDate));
  const [errorDescription, seterrorDescription] = useState(false);
  const [errorEvent, setErrorEvent] = useState(false);
  const [errorduration, setErrorduration] = useState(false);
  const [groupCategory, setgroupCategory] = useState([]);
  const [groupType, setgroupType] = useState([]);
  const [notifyMembers, setNotifyMembers] = useState(false); // State for the checkbox
const [grpId ,setGrpId] =useState("");
  const {
    loading: grpTypeLoading,
    error: grpTypeError,
    data: grpTypeData,
  } = useQuery(GET_GROUP_TYPE);
  const {
    loading: grpCatLoading,
    error: grpCatError,
    data: grpCatData,
  } = useQuery(GET_GROUP_CATEGORY);

  // console.log("this is groupType", grpCatData?.group_category);
  useEffect(() => {
    setgroupType(grpTypeData?.group_type);
    // console.log("inside use effect", grpCatData?.group_category);
    setgroupCategory(grpCatData?.group_category);
  }, [grpCatData, grpTypeData]);
  // console.log("group category map", groupCategory);

  const [insertGroupHeaderInfo, { loading, error }] = useMutation(
    INSERT_GROUP_HEADER_INFO_MUTATION,
    {
      onError: (error) => {
        console.error("Mutation Error:", error.message);
      },
      onCompleted: (data) => {
        // console.log("pratik", data.insert_group_master.returning[0]);
        handleNext();
        localStorage.setItem(
          "group_id",
          data.insert_group_master.returning[0].id
        );
        setGrpId(data.insert_group_master.returning[0].id)
      },
    }
  );
  const [insertGroupChatNotification] = useMutation(
    INSERT_GROUP_CHAT_NOTIFICATION_MUTATION,
    {
      onError: (error) => {
        console.error("Mutation Error:", error.message);
      },
    }
  );
  const handleCheckboxChange = (event) => {
    setNotifyMembers(event.target.checked);
  };
  const handleNext = () => {
    // console.log(props.data);
    setNewData(data);
    setNewDate(date);
    // props.setData(setdata)
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  
  function calculateDuration(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let years = end.getUTCFullYear() - start.getUTCFullYear();
    let months = end.getUTCMonth() - start.getUTCMonth();
    let days = end.getUTCDate() - start.getUTCDate();

    if (days < 0) {
        months--;
        const prevMonth = new Date(end);
        prevMonth.setUTCMonth(end.getUTCMonth() - 1);
        days += new Date(prevMonth.getUTCFullYear(), prevMonth.getUTCMonth() + 1, 0).getUTCDate();
    }

    if (months < 0) {
        years--;
        months += 12;
    }

    let duration = '';

    if (years > 0) {
        duration += `${years} year${years > 1 ? 's' : ''} `;
    }
    if (months > 0) {
        duration += `${months} month${months > 1 ? 's' : ''} `;
    }
    if (days > 0) {
        duration += `${days} day${days > 1 ? 's' : ''}`;
    }

    return duration.trim();
}


  // function calculateDuration(startDate, endDate) {
  //   const diffInMs = Math.abs(new Date(endDate) - new Date(startDate));
  //   const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  //   if (diffInDays > 31) {
  //     const diffInMonths = Math.floor(diffInDays / 31);
  //     return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""}`;
  //   } else {
  //     return `${diffInDays} day${diffInDays > 1 ? "s" : ""}`;
  //   }
  // }
  
  // useEffect(()=>{
  //   if(newDate?.startDate){
  //     setDate('startDate',newDate?.startDate)
  //   }
  // },[newDate])

  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    setDate((prevdata) => {
      return {
        ...prevdata,
        [key]: formattedDate,
        duration: calculateDuration(prevdata.startDate, value),
      };
    });
    handleChange(key, formattedDate);
  };

  const [errors, setErrors] = useState({});

  const adddata = async () => {
    const newErrors = {};
        // Validate name
        if (!data.groupname) {
          newErrors.groupname = "Course Name is required";
        }
        if (!data.category) {
          newErrors.category = "Category Name is required";
        }
        if (!data.type) {
          newErrors.type = "type is required";
        }
        if (!data.startDate) {
          newErrors.startDate = "Start date is required";
        }
        // if (!data.start_date) {
        //   newErrors.start_date = "Start date is required";
    
        // }
        // Error
        if (!data.endDate) {
          // console.log(data.endDate)
          newErrors.endDate = "End date is required";
        }
          // if (!data.duration) {
          //   newErrors.duration = "duration is required";
          // }
        // price erroe
        if (!data.price) {
          newErrors.price = "price is required";
        }
        if (!data.description) {
          console.log("Error:")
          newErrors.description = "Description is required";
        }
    
        setErrors(newErrors)

      try {
        const { data:insertedData } = await insertGroupHeaderInfo({
          variables: {
            decription: data["description"],
            name: data["groupname"],
            activation_date: date["startDate"]? date["startDate"]:newDate?.startDate,
            deactivation_date: date["endDate"]?date["endDate"]:newDate?.endDate,
            category_id: data["category"],
            type: data["type"],
            level_up_group: 2,
            price: data["price"],
            duration: date["duration"]?date["duration"]:newDuration, 
            status:'isdeactive',
          },
        });
        if (notifyMembers) {
          await insertGroupChatNotification({
            variables: {
              group_id: grpId,
              sender_id: localStorage.getItem('id'), // Replace with the actual sender_id
            },
          });
        }
        console.log("instered group header info",insertedData)
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    // }
  };

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "800px",
        flexWrap: "wrap",
        flexDirection: "row",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box>
          <TextField
            sx={{
              width: "360px",
              m: 1,
              minWidth: "360px",
              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
              },
              "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                fontFamily: "MontserratRegular",
                color: " #999999",
                fontSize: "14px",
                lineHeight: " 20px",
              },
            }}
            required
            label="Course Name"
            size="small"
            value={data.groupname}
            onChange={(e) => {
              handleChange("groupname", e.target.value);
            }}
            error={!!errors.groupname}
            helperText={errors.groupname}
            id="standard-required"
          />
          {errorEvent && (
            <Typography sx={{ color: "red" }}>enter event name</Typography>
          )}
        </Box>
        <Box>
          <FormControl
            sx={{ width: "360px", m: 1, fontFamily: "MontserratRegular" }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                fontFamily: "MontserratRegular",
                color: "#999999",
                fontSize: "14px",
              }}
              size="small"
            >
              Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.category}
              label="Category"
              onChange={(e) => {
                handleChange("category", e.target.value);
              }}
              error={!!errors.category}
              helperText={errors.category}
              placeholder="category"
              size="small"
              sx={{ fontFamily: "MontserratRegular" }}
              //   onChange={handleChange}
            >
              {groupCategory?.map((item, index) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", width: "380px", flexDirection: "column" }}>
          <FormControl
            sx={{
              width: "360px",
              mt: 1,
              marginLeft: 1,
              mb: 1,
              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
              },
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                fontFamily: "MontserratRegular",
                color: "#999999",
                fontSize: "14px",
              }}
              size="small"
            >
              Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.type}
              label="Type"
              size="small"
              sx={{
                fontFamily: "MontserratRegular",
                "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
                  width: "100%",
                },
              }}
              //   onChange={handleChange}
              onChange={(e) => {
                handleChange("type", e.target.value);
              }}
              error={!!errors.type}
              helperText={errors.type}
            >
              {groupType?.map((item, index) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <TextField
            sx={{
              width: "360px",
              mt: 1,
              marginLeft: 1,
              mb: 1,
              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
              },
              "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                fontFamily: "MontserratRegular",
                color: " #999999",
                fontSize: "14px",
                lineHeight: " 20px",
              },
            }}
            required
            size="small"
            value={newDuration?newDuration:date.duration}
            onChange={(e) => {
              handleChange("duration", e.target.value);
            }}
            error={!!errors.duration}
            helperText={errors.duration}
            id="standard-required"
            // label="duration"
            placeholder={newDuration?newDuration:"Duration"}
          />
          {errorduration ? (
            <Typography sx={{ color: "red", mx: 2 }}>
              Please Enter Duration
            </Typography>
          ) : (
            ""
          )}
        </Box>
        <Box>
          <TextField
            sx={{
              width: "360px",
              mt: 1,
              marginLeft: 1,
              mb: 1,
              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
              },
              "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                fontFamily: "MontserratRegular",
                color: " #999999",
                fontSize: "14px",
                lineHeight: " 20px",
              },
            }}
            required
            size="small"
            value={data.price}
            onChange={(e) => {
              handleChange("price", e.target.value);
            }}
            error={!!errors.price}
            helperText={errors.price}
            id="standard-required"
            label="Price"
          />
        </Box>
      </Box>
      <Box>
        <Box sx={{ mt: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="YYYY/MM/DD"
              format="YYYY/MM/DD"
              size="small"
              label={newDate?.startDate?newDate?.startDate:"Enter start date"}
              sx={{
                width: "360px",
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                  height: "40px",
                },
                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",
                  color: "#999999",
                  fontSize: "14px",
                  lineHeight: "15px",
                },
              }}
              onChange={(e) => {
                handleDateChange("startDate", e);
              }}
              error={!!errors.startDate}
              helperText={errors.startDate}
              value={date.startDate}
            />
          </LocalizationProvider>
        </Box>
        <Box>
          {/* ..........end date */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              size="small"
              label={newDate?.endDate?newDate?.endDate:"Enter end date"}
              format="YYYY/MM/DD"
              inputFormat="YYYY/MM/DD"
              sx={{
                width: "360px",
                marginTop: "16px",
                marginBottom: "0px",
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                  height: "40px",
                },
                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",
                  color: "#999999",
                  fontSize: "14px",
                  lineHeight: "15px",
                },
              }}
              onChange={(e) => {
                handleDateChange("endDate", e);
              }}
              value={date.endDate}
              error={!!errors.endDate}
              helperText={errors.endDate}
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <Box sx={{ width: "50%" }}>
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              size="small"
              rows={3.5}
              value={data.description}
              sx={{
                width: "360px",
                mt: 2,
                marginLeft: 0,
                mb: 1,
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                },
                "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",
                  color: " #999999",
                  fontSize: "14px",
                  lineHeight: " 20px",
                },
              }}
              onChange={(e) => {
                handleChange("description", e.target.value);
              }}
              error={!!errors.description}
              helperText={errors.description}
              //   defaultValue="Default Value"
            />
            {errorDescription && (
              <Typography sx={{ color: "red" }}>
                Please Enter Description
              </Typography>
            )}
          </Box>
          <FormControlLabel
        control={
          <Checkbox
            checked={notifyMembers}
            onChange={handleCheckboxChange}
            // color="primary"
            sx={{
              color: "#49454F",
              "&.Mui-checked": {
                color: "#005e89",
              },
            }}
          />
        }
        label="Click here to Notify members about this group"
      />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <Button
            onClick={adddata}
            variant="contained"
            sx={{
              ml: 32,
              width: "100px",
              color: "#FFFFFF",
              backgroundColor: "#005e89",
              height: 30,
              fontFamily: "MontserratRegular",
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
}