import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";

const FETCH_USER_EVENTS =gql`
query Get2s($_eq: uuid = "") {
  user_events(where: {userid: {_eq: $_eq}, _and: {event_master: {is_recurring: {_eq: false}, _and: {publish_status: {_eq: "published"}}}}}, distinct_on: event_id) {
    event_master {
      cover_img_path
      activation_date
      closure_date
      deactivation_date
      description
      end_time
      id
      location
      name
      parent_id
      start_time
      recurrance_end_time
      recurrance_end_date
      recurrance_start_date
      recurrance_start_time
    }
  }
}
`
const FETCH_CAL_EVENTS =gql`
query getevents_byname($_eq: String = "") {
  event_master(where: {name: {_eq: $_eq}}) {
    activation_date
    closure_date
    cover_img_path
    deactivation_date
    end_time
    description
    id
    location
    name
    metadata
    is_recurring
    publish_to
    recurrance_end_date
    start_time
    recurrance_start_date
    recurrance_frequency
  }
}
`
// const FETCH_BOOKED_EVENTS =gql`

// `

export default function ScheduleComponent() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(1);
  const [isUser,setisUser] = useState(true); 
  const [CalendarId,SetCalendarId] = useState("");
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([])
  const calendarRef = useRef(null);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState("month");
  const [EventName, setEventName] = useState('')
  const onViewChange = (view) => {
    setView(view);
  };
  // console.log('events calendar',EventsData)
  const handleEventClick = (event,id,name,recurrance_start_date,recurrance_end_date,start_time,end_time,location,imgUrl) => {
    setisUser(true);
    let membership1 = true;
    console.log("it is calendar id ::",recurrance_start_date,event,id,name);
    navigate("/eventmanagement2",{state:{isUser: isUser,id,event,membership1,name,recurrance_start_date,recurrance_end_date,start_time,end_time,location,imgUrl}})
    setEventName(name)
  }

  const [EventsData, setEventsdata] = useState([]);
  const { loading, error, data:eventsdata,isError } = useQuery(FETCH_USER_EVENTS, {
    variables: {
      _eq:localStorage.getItem('id'),
          },
  })
  ;


  useEffect(() => {
    if (eventsdata && eventsdata.group_master) {
      const eventDataArray = eventsdata.group_master.map((group) => group.group_events);
      setEventsdata(eventDataArray);
      // console.log("data1", eventsdata?.group_master);
    }
  }, [eventsdata]);
  
  const [CalEvents, setCalEvents] = useState([]);
  const { error:error1, data:calevents, } = useQuery(FETCH_CAL_EVENTS, {
    variables: {
      _eq: EventName,
          },
          onCompleted:(data)=>{
            // console.log("success done",data)
          },
          onError:(error)=>{
            // console.log("error error dsz,vjbsljdnv,js dvbkjdbvjds ",error)
          }
  })
  ;
  useEffect(() => {
    setCalEvents(calevents?.event_master[0]);
     SetCalendarId (calevents?.event_master[0]?.id);
  },[calevents]);
  useEffect(() => {
    const eventsArr = EventsData?.map(group => 
      group.map(item => ({
        id: item.event_master.id,
        title: item.event_master.name,
        start: new Date(item.event_master.recurrance_start_date),
        end: new Date(item.event_master.recurrance_end_date),
      }))
    ).flat();
  
    setEvents(eventsArr);
  }, [EventsData]);
  
  const events11 = eventsdata?.user_events?.map((eventData) => {
    // You may need to map your GraphQL data to match the format expected by react-big-calendar
    return {
      // Map your event properties here
      id: eventData.event_master.id,
      title: eventData?.event_master.name,
      start: new Date(eventData.event_master?.recurrance_start_date),
      end: new Date(eventData.event_master?.recurrance_end_date),
      recurrance_start_date:eventData.event_master?.recurrance_start_date,
      recurrance_end_date:eventData.event_master?.recurrance_end_date,
      start_time:eventData.event_master?.start_time,
      end_time:eventData.event_master?.end_time,
      location: eventData.event_master.location,
      imgUrl: eventData.event_master.cover_img_path
      // Add other properties as needed
    };
  });
  console.log("these are the events fetched from the server",events11)

  const calendarStyle = {
    fontFamily: "MontserratRegular",
    color: "#252525",
    "& .rbc-calendar ": {
      width: "100%",
    },
    "& .rbc-calendar": {
      fontFamily: "MontserratRegular",
      backgroundColor: "red",
      width: "100%",
      "& ..rbc-event": { backgroundColor: "#005e89" },
      // height:"80px"
    },
    "& ..rbc-event, .rbc-day-slot .rbc-background-event": {
      backgroundColor: "#005e89",
    },
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.backgroundColor || "#005e89"; // Set a default background color if none is provided
    const style = {
      backgroundColor,
      borderRadius: "10px",
      opacity: isSelected ? 1 : 0.9,
      color: "white",
      border: "0",
      display: "block",
      width: "100%",
      // height:"100%",
      marginTop: "0px",
      paddingTop: "0px",
      fontSize: "14px",
      fontFamily: "MontserratRegular",
    };
    return {
      style,
    };
  };

  const onNavigate = (date) => {
    setDate(date);
  };

  return (
    <Box sx={styles.main}>
      {/* ........................Schedule starts here................. */}
      <Box sx={styles.top}>
        
      <Box style={{display:"flex",width:"600px",height:"100%",margin:"auto"}}>
        <Calendar
          ref={calendarRef}
          localizer={localizer}
          events={events11}
          view={view}
          onView={onViewChange}
          date={date}
          onNavigate={onNavigate}
          style={calendarStyle}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={(event)=>handleEventClick(event,event.id, event?.title,event?.recurrance_start_date,event?.recurrance_end_date,event?.start_time,event?.end_time,event?.location,event?.imgUrl )}
        />
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginX: "15px",
    marginY: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    minWidth: "320px",
    overflow: "hidden",
    // height: "90vh",
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    height: "600px",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  schedule: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "8px",
    minWidth: "320px",
  },

  heading: {
    fontFamily: "MontserratMedium",
    fontSize: "20px",
    marginLeft: "8px",
    marginTop: "8px",
    marginRight: "8px",
  },

  topStrip: {
    width: "100%",
    height: "35px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #999999",
    marginY: "8px",
    borderRadius: "10px",
  },

  day: {
    width: "30%",
    marginY: "10px",
    fontFamily: "MontserratRegular",
  },
  textBox: {
    width: "33.33%",
    display: "flex",
    height: "37px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  smallTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "14px",
  },
  bottom: {
    display: "flex",
    margin: "10px",
    height: "72vh",
  },
};
