
import { React, forwardRef, useEffect, useState } from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { TimePicker } from "@mui/x-date-pickers";
import { gql, useQuery, useMutation } from "@apollo/client";
const GET_SCHEDULES_QUERY = gql`query MyQuery16($_eq: bigint = "") {
  schedules(where: {event_id: {_eq: $_eq}}) {
    description
    end_time
    event_id
    owner
    start_time
    id
  }
}
`
const GET_STAFF_QUERY=gql`
query getAllStaff {
  profile_details(where: {user: {defaultRole: {_eq: "staff"}}}) {
    bio
    birth_date
    category
    first_name
    last_name
    profile_pic_path
    second_name
    user_id
    user {
      defaultRole
      displayName
      email
      emailVerified
      id
      phoneNumber
    }
  }
}
`
const ADD_SCHEDULES_QUERY = gql`mutation MyMutation13($description: String = "", $end_time: time = "", $event_id: bigint = "", $owner: uuid = "", $start_time: time = "") {
  insert_schedules(objects: {description: $description, end_time: $end_time, event_id: $event_id, owner: $owner, start_time: $start_time}) {
    returning {
      description
      end_time
      event_id
      id
      owner
      start_time
    }
  }
}
`
const UPDATE_SCHEDULES_QUERY=gql`
mutation updateSchedule($_eq: Int = "", $description: String = "", $end_time: time = "", $owner: uuid = "", $start_time: time = "") {
            update_schedules(where: {id: {_eq: $_eq}}, _set: {description: $description, end_time: $end_time, owner: $owner, start_time: $start_time}) {
              returning {
                description
                end_time
                owner
                start_time
                id
              }
            }
          }

`
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditEvent(props) {
  //   const [open, setOpen] = React.useState(false);
    //  console.log("start time", props.start_time);
  const oldData = props.oldData;
  console.log("old data", oldData);

  console.log("start time", props.oldData.start_time);

  const starttime=props.oldData.start_time;
  const endtime=props.oldData.end_time;
  const eventId = props.event_id;
  const staffId = props.staff;
  console.log("staff id",props.staff);
  const scheduleId = props.scheduleId;
  console.log("schedule id", props.scheduleId);
  const { onClose, open } = props;

  const handleClickOpen = () => {
    props.setOpenpop(true);
  };

  const handleClose = () => {
    props.setOpenpop(false);
  };
  const [staff, setStaff] = useState([]);

  const { loading:loading1, error:error1, data:sf } = useQuery(GET_STAFF_QUERY)
;
console.log("error is ", error1);
 
console.log("set data staff", sf);
useEffect(() => {
  setStaff(sf?.profile_details);
  console.log("staffdata",sf?.profile_details)
},[sf]);
console.log("data for staff",staff)
const [schedule, setschedule] = useState([]);

  const { loading, error, data:sc,isError ,refetch} = useQuery(GET_SCHEDULES_QUERY, {

    variables: {
            _eq: eventId,
          },
  })
  
  ;
 
    console.log("error is ", error);
 
  console.log("set data scheduled", sc);

 

  useEffect(() => {

    setschedule(sc?.schedules);
    console.log("data1",sc?.schedules)
  },[sc]);
  console.log("data for schedule",schedule)

  const [dropdown, setDropdown] = useState(staffId);
  const [formData, setFormData] = useState({});
  const [update, setUpdate] = useState(props?.isForUpdate);
console.log("is for update",props?.isForUpdate);
console.log("IS FOR UPDATE",update)
  const [insertschedules] = useMutation(ADD_SCHEDULES_QUERY,{
    onError:(errors)=>{
      console.log("error while updating data",errors);
    },
    onCompleted:(data)=>{
        console.log("first Add to schedule",data)
        console.log("first Add to schedule",data.insert_schedules.returning[0].id)
        refetch();
        // profileUpdates(data.insertUsers.returning[0].id)
        handleSave(data.insert_profile_details.returning[0].event_id)

    },
    refetchQueries: [
      // Provide the name of the query you want to refetch after deletion
      // For example:
       { query: GET_SCHEDULES_QUERY}
    ],
  });
  const [updateschedules] = useMutation(UPDATE_SCHEDULES_QUERY,{
    onError:(errors)=>{
      console.log("error while updating data",errors);
    },
    onCompleted:(data)=>{
        console.log("first Add to schedule",data)
        console.log("first Add to schedule",data.update_schedules.returning[0].id)
        refetch();
        // profileUpdates(data.insertUsers.returning[0].id)
        handleSave(data.update_schedules.returning[0].id)

    },
    refetchQueries: [
      // Provide the name of the query you want to refetch after deletion
      // For example:
       { query: GET_SCHEDULES_QUERY}
    ],
  });
  const handleSave = async (id) => {
    console.log("inserting data to schedule",eventId,data["description"],data["start_time"],data["end_time"], dropdown,
    )
  if (update)
   {

    try {
      const { data:updated } = await updateschedules({
        variables: {
     
          _eq: scheduleId,
          description: data["description"] || "",
          start_time: data["start_time"] || "",
          end_time: data["end_time"] || "",
          owner: dropdown || "",
 
        },
         });
        //  console.log("last",updatenew)
    } catch (error) {
      console.error('GraphQL Mutation Error:', error.message);
    }

  }

else{
    try {
      const { data:updated } = await insertschedules({
        variables: {
        
                    event_id: eventId,
                    description: data["description"],
                    start_time: data["start_time"],
                    end_time: data["end_time"],
                    owner: dropdown,
        },
         });
        //  console.log("last",updatenew)
    } catch (error) {
      console.error('GraphQL Mutation Error:', error.message);
    }
  }
        props.setOpenpop(false);
  };



  // const fetchstaff = async () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "x-hasura-admin-secret",
  //     process.env.APP_NHOST_SECREATEKEY
  //   );
  //   myHeaders.append("Access-Control-Allow-Credentials", true);
  //   myHeaders.append("Access-Control-Allow-Origin", "*");

  //   myHeaders.append(
  //     "Access-Control-Allow-Methods",
  //     "GET,OPTIONS,PATCH,DELETE,POST,PUT"
  //   );
  //   myHeaders.append(
  //     "Access-Control-Allow-Headers",
  //     "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
  //   );
  //   var graphql = JSON.stringify({
  //     query: `query getAllStaff {
  //       profile_details(where: {users: {defaultRole: {_eq: "Staff"}}}) {
  //         bio
  //         birth_date
  //         category
  //         first_name
  //         last_name
  //         profile_pic_path
  //         second_name
  //         user_id
  //         users {
  //           defaultRole
  //           displayName
  //           email
  //           emailVerified
  //           id
  //           phoneNumber
  //         }
  //       }
  //     }`,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: graphql,
  //     redirect: "follow",
  //     // mode: "no-cors",
  //   };

  //   const res = await fetch(process.env.REACT_APP_URL, requestOptions);
  //   const data1 = await res.json();

  //   setStaff(data1.data.profile_details);
  // };

  // const handleSave = async () => {
  //   if (props.isForUpdate) {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append(
  //       "x-hasura-admin-secret",
  //       process.env.REACT_APP_NHOST_SECREATEKEY
  //     );
  //     myHeaders.append("Access-Control-Allow-Credentials", true);
  //     myHeaders.append("Access-Control-Allow-Origin", "*");

  //     myHeaders.append(
  //       "Access-Control-Allow-Methods",
  //       "GET,OPTIONS,PATCH,DELETE,POST,PUT"
  //     );
  //     myHeaders.append(
  //       "Access-Control-Allow-Headers",
  //       "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
  //     );
  //     var graphql = JSON.stringify({
  //       query: `mutation updateSchedule($_eq: Int = "", $description: String = "", $end_time: timetz = "", $owner: uuid = "", $start_time: timetz = "") {
  //           update_schedules(where: {id: {_eq: $_eq}}, _set: {description: $description, end_time: $end_time, owner: $owner, start_time: $start_time}) {
  //             returning {
  //               description
  //               end_time
  //               owner
  //               start_time
  //               id
  //             }
  //           }
  //         }`,

  //       variables: {
  //         _eq: scheduleId,
  //         description: data["description"] || "",
  //         start_time: data["start_time"] || "",
  //         end_time: data["end_time"] || "",
  //         owner: dropdown || "",
  //       },
  //     });
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: graphql,
  //       redirect: "follow",
  //       // mode: "no-cors",
  //     };

  //     const res2 = await fetch(process.env.REACT_APP_URL, requestOptions);
  //     const data2 = await res2.json();
  //     console.log("Update Schedules ", data2.data);
  //     // console.log("this checking in api",id,dropdown,selectedValue)
  //     props.setOpenpop(false);
  //   } else {
  //     console.log(data);
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append(
  //       "x-hasura-admin-secret",
  //       process.env.REACT_APP_NHOST_SECREATEKEY
  //     );
  //     myHeaders.append("Access-Control-Allow-Credentials", true);
  //     myHeaders.append("Access-Control-Allow-Origin", "*");

  //     myHeaders.append(
  //       "Access-Control-Allow-Methods",
  //       "GET,OPTIONS,PATCH,DELETE,POST,PUT"
  //     );
  //     myHeaders.append(
  //       "Access-Control-Allow-Headers",
  //       "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
  //     );
  //     var graphql = JSON.stringify({
  //       query: `mutation MyMutation13($description: String = "", $end_time: timetz = "", $event_id: Int = "", $owner: uuid = "", $start_time: timetz = "") {
  //       insert_schedules(objects: {description: $description, end_time: $end_time, event_id: $event_id, owner: $owner, start_time: $start_time}) {
  //         returning {
  //           description
  //           end_time
  //           event_id
  //           id
  //           owner
  //           start_time
  //         }
  //       }
  //     }`,
  //       variables: {
  //         event_id: eventId,
  //         description: data["description"],
  //         start_time: data["start_time"],
  //         end_time: data["end_time"],
  //         owner: dropdown,
  //       },
  //     });
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: graphql,
  //       redirect: "follow",
  //       // mode: "no-cors",
  //     };

  //     const res = await fetch(process.env.REACT_APP_URL, requestOptions);
  //     const data1 = await res.json();
  //     console.log("pratik", data1.data);
  //     props.setOpenpop(false);
  //     // alert("insert");
  //   }
  // };

  // const [schedule, setschedule] = useState([]);
  // console.log("setschedule", schedule);

  const fetchdata = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "x-hasura-admin-secret",
      process.env.REACT_APP_NHOST_SECREATEKEY
    );
    myHeaders.append("Access-Control-Allow-Credentials", true);
    myHeaders.append("Access-Control-Allow-Origin", "*");

    myHeaders.append(
      "Access-Control-Allow-Methods",
      "GET,OPTIONS,PATCH,DELETE,POST,PUT"
    );
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
    );

    var graphql = JSON.stringify({
      query: `query MyQuery16($_eq: Int = "") {
                schedules(where: {event_id: {_eq: $_eq}}) {
                  description
                  end_time
                  event_id
                  owner
                  start_time
                  id
                }
              }`,
      variables: {
        _eq: eventId,
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
      // mode: "no-cors",
    };

    const res = await fetch(process.env.REACT_APP_URL, requestOptions);
    const data = await res.json();
    // settype(data.data.event_type)

    console.log("asdasd", data.data.schedules);
    setschedule(data.data.schedules);
  };

  // React.useEffect(() => {
  //   // Calling fetchdata Method
  //   fetchdata();
  //   fetchstaff();
  // }, []);

  const [data, setdata] = useState(oldData);
  const handleChange = (key, value) => {
    console.log(key, value);
    setdata({
      ...data,
      [key]: value,
    });
  };

  const handleTimeChange = (key, value) => {
    let formattedTime = value.format("hh:mm a");
    console.log(formattedTime);
    setdata((prevdata) => {
      return {
        ...prevdata,
        [key]: formattedTime,
      };
    });
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
                Slide in alert dialog
            </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          sx={{
            height: 50,
            width: 600,
            backgroundColor: "#005e89",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            style={{ marginLeft: 10 }}
            src={require("../../images/navLogo.png")}
          />
          <Button onClick={handleClose}>
            <Typography sx={{ color: "white" }}>Close</Typography>{" "}
          </Button>
        </Box>

        {/* Group Category */}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 5,
          }}
        >
          {/* <ThemeProvider theme={theme}> */}
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                size="Start Time"
               // label="Enter start time"
                label={starttime}
                placeholder={starttime}
                ap={true}
                format="h:mm A "
                sx={{
                  width: "220px",
                  marginRight: "30px",
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                    height: "40px",
                  },
                  "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    fontSize: "14px",
                    lineHeight: "10px",
                  },
                }}
                value={data?.start_time}
                onChange={(e) => {
                  handleTimeChange("start_time", e);
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    placeholder="Event Name"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]{2}:[0-9]{2} [AaPp][Mm]",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            {/* </ThemeProvider> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                size="End Time"
                // label="Enter end time"
                label={endtime}
                placeholder={endtime}
                ampm={true}
                format="h:mm A "
                sx={{
                  width: "220px",
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                    height: "40px",
                  },
                  "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    fontSize: "14px",
                    lineHeight: "10px",
                  },
                }}
                value={data?.end_time}
                onChange={(e) => {
                  handleTimeChange("end_time", e);
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ width: "90%", display: "flex", justifyContent: "center" }}>
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              size="small"
              rows={3.5}
              sx={{
                width: "80%",
                mt: 2,
                marginLeft: 0,
                mb: 1,
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                },
                "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",
                  color: " #999999",
                  fontSize: "14px",
                  lineHeight: " 20px",
                },
              }}
              value={data?.description}
              onChange={(e) => handleChange("description", e.target.value)}
            />
          </Box>

          <Box sx={{ width: "90%", display: "flex", justifyContent: "center" }}>
            <FormControl
              sx={{ width: "80%", m: 1, fontFamily: "MontserratRegular" }}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  fontFamily: "MontserratRegular",
                  color: "#999999",
                  fontSize: "14px",
                }}
                size="small"
              >
                Owner
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                label="Assign to"
                sx={{ width: "100%" }}
                // onChange={handleChange}
                value={dropdown}
                onChange={(e) => setDropdown(e.target.value)}
              >
                {staff.map((row) => (
                  <MenuItem value={row.user?.id}>{row.first_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {/* Duration */}
        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></Box> */}
        {/* Attendance Capacity */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Price */}

          <Box
            sx={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              my: 3,
            }}
          >
            <Button
              sx={{
                width: "30%",
                backgroundColor: "#005e89",
                color: "#FFFFFF",
                height: 40,
                marginRight: 3,
              }}
              variant="conatined"
              onClick={handleSave}
            >
              {" "}
              SAVE
            </Button>
            <Button
              sx={{
                width: "30%",
                backgroundColor: "#FFFFFF",
                color: "#999999",
                height: 40,
                border: 1,
                //   margin: 3,
              }}
              variant="conatined"
              onClick={handleClose}
            >
              {" "}
              CANCEL
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
