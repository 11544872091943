import * as React from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  DesktopDatePicker,
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { createTheme } from "@mui/material/styles";
import { useNhostClient } from "@nhost/react";
import { ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
// import {GET_EVENTS_QUERY} from "../../../pages/EventManagement2.js";
import { addDays, addWeeks, addMonths, addYears } from 'date-fns';
import { format } from 'date-fns';
import {
  useAccessToken,
  useFileUpload,
  useFileUploadItem,
  useMultipleFilesUpload,
  id,
  useSignOut,
  useAuthenticationStatus,
} from "@nhost/react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useLocation } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiPickersStaticWrapper: {
      styleOverrides: {
        root: {
          "& .MuiPickersBasePicker-pickerView": {
            padding: "0px",
            height: "10px",
          },
        },
      },
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const GET_EVENTS_QUERY = gql`
  query GetAllEventInfo($id: bigint) {
    event_master(where: { id: { _eq: $id } }) {
      activation_date
      closure_date
      created_at
      cover_img_path
      deactivation_date
      description
      id
      location
      metadata
      name
      parent_id
      is_recurring
      recurrance_end_date
      recurrance_frequency
      recurrance_start_date
      start_time
      end_time
      type_id
      updated_at
      event_category {
        created_by
        created_date
        description
        id
        name
      }
      event_faculties {
        event_id
        faculty_type_id
        id
        user_id
      }
      event_type {
        created_by
        created_date
        description
        id
        name
      }
    }
  }
`;

const GET_EVENT_TYPE = gql`
  query GetEventType {
    event_type {
      created_by
      created_date
      description
      id
      name
    }
  }
`;

const GET_EVENT_CATEGORY = gql`
  query GetEventCategory {
    event_category {
      created_by
      created_date
      description
      id
      name
    }
  }
`;

const UPDATE_EVENT_MASTER = gql`
  mutation MyMutation4(
    $category_id: bigint = ""
    $cover_img_path: String = ""
    $description: String = ""
    $location: String = ""
    $name: String = ""
    $recurrance_end_date: date = ""
    $recurrance_start_date: date = ""
    $_eq: bigint = ""
    $type_id: bigint = ""
    $metadata: jsonb = ""
    $start_time: timetz = ""
    $end_time: timetz = ""
    $conven_meeting : Boolean 
  ) {
    update_event_master(
      where: { id: { _eq: $_eq } }
      _set: {
        category_id: $category_id
        cover_img_path: $cover_img_path
        description: $description
        location: $location
        name: $name
        recurrance_end_date: $recurrance_end_date
        recurrance_start_date: $recurrance_start_date
        type_id: $type_id
        metadata: $metadata
        start_time: $start_time
        end_time: $end_time
        conven_meeting:$conven_meeting
      }
    ) {
      returning {
        cover_img_path
        description
        id
        location
        name
        metadata
      }
    }
  }
`;

const DELETE_RECURRING_EVENTS = gql`
mutation MyMutation4($_eq: date = "", $_eq1: date = "", $_eq2: bigint = "") {
  delete_event_master(where: {recurrance_start_date: {_eq: $_eq}, _and: {recurrance_end_date: {_eq: $_eq1}, parent_id: {_eq: $_eq2}}}) {
    returning {
      recurrance_end_time
      recurrance_end_date
    }
  }
}
`;
const INSERT_RECEVENT_HEADER_INFO_MUTATION = gql`
  mutation InsertRecEventHeaderInfo(
    $category_id: bigint
    $name: String
    $activation_date: date
    $description: String
    $deactivation_date: date
    $recurrance_start_date: date
    $recurrance_end_date: date
    $recurrance_frequency: String
    $type_id: bigint
    $location: String
    $start_time: timetz
    $end_time: timetz
    $is_recurring: Boolean
    $parent_id: bigint
    $publish_status: String
    $cover_img_path: String = ""

  ) {
    insert_event_master(
      objects: {
        category_id: $category_id
        name: $name
        activation_date: $activation_date
        description: $description
        deactivation_date: $deactivation_date
        recurrance_end_date: $recurrance_end_date
        recurrance_start_date: $recurrance_start_date
        recurrance_frequency: $recurrance_frequency
        type_id: $type_id
        location: $location
        start_time: $start_time
        end_time: $end_time
        is_recurring: $is_recurring
        parent_id: $parent_id
        publish_status: $publish_status
        cover_img_path: $cover_img_path

      }
    ) {
      returning {
        activation_date
        category_id
        created_at
        deactivation_date
        description
        id
        metadata
        name
        recurrance_end_date
        recurrance_frequency
        recurrance_start_date
        type_id
        updated_at
        location
        is_recurring
        start_time
        end_time
        parent_id
        publish_status
      }
    }
  }
`;

export default function EditEvent(props) {
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError: imgErr,
    progress,
    id: cover_img_path,
    bucketId,
    name,
  } = useFileUpload();
  const location = useLocation();
  const {recurrance_frequency} = location.state;

  const [updatedData, setUpdatedData] = useState({
    name: props.name,
    description: props.description,
    startDate: props.sdate,
    endDate: props.edate,
    event_type: props.event_type,
    event_category: props.event_category,
    location: props.location,
    attendanceCapacity: props.attendanceCapacity,
    duration: props.duration,
    startTime: props.startTime,
    endTime: props.endTime,
    eventCatId:props.eventCatId,
    eventTypeId:props.eventTypeId,
    eventRecFeq:recurrance_frequency
  });
  const [vcMode, setVcMode] = useState(props.vcMode);
  const [data, setdata] = React.useState({});
  const [isfreq, setIsfreq] = useState(false);
  const [selectfreq, setSelectfreq] = useState("");
  // console.log("getting this rightobject",props.edate)
  const frequency = [
    { id: 1, name: null },
  
    { id: 2, name: "Daily" },
  
    { id: 3, name: "Weekly" },
  
    { id: 4, name: "Monthly" },
  
    { id: 5, name: "Yearly" },
  ];
  const [startt, setStartt] = useState(new Date());

  //   const [open, setOpen] = React.useState(false);
  // console.log("propedvalue for faculty id", props);  
  const { onClose, open } = props;
  // console.log("id is coming from props pppp", recurrance_frequency);
  const [Events, setEvents] = useState([]);

  const handleClickOpen = () => {
    props.setOpenpop(true);
  };

  const handleClose = () => {
    props.setOpenpop(false);
  };

  const [deleterecurringevent,] =useMutation(DELETE_RECURRING_EVENTS,{
    onCompleted:(data)=>{
      // console.log("recurring events deleted",data.delete_event_master.returning)
    }
    });

    const [insertRecEventHeaderInfo, { loading1, error1 }] = useMutation(
      INSERT_RECEVENT_HEADER_INFO_MUTATION,
      {
        onError: (error1) => {
          console.error("Mutation Error:", error1.message);
        },
        onCompleted: (data) => {
          // console.log("pratik", data.insert_event_master.returning[0]);
        },
      }
    );

  const { refetch, data: data3 } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      id: props.id,
    },
  });

  useEffect(() => {
    setEvents(data3?.event_master);
    // setEventType(data?.event_master[0]?.event_type.name)
  }, [data3]);

  const [UpdateEventMetadata, { loading, error, isError, isSuccess }] =
    useMutation(UPDATE_EVENT_MASTER, {
      onError: (error) => {
        console.error("Mutation Error:", error);
      },
      onCompleted: (data) => {
        // console.log("updated edit event data:", data);
        props.setOpenpop(false);
        props.setOpen(false);
        // alert("Event updated successfully");

        // localStorage.setItem(
        //   "group_id",s
        //   data.insert_group_master.returning[0].id
        // );
        refetch();
      },
      refetchQueries: [{ query: GET_EVENTS_QUERY }],
    });

    // console.log("this is saved cover image",props.image);

  const handleSave = async () => {
    // console.log("this is saved cover image",cover_img_path,props.image);
    try {
      const { data } = await UpdateEventMetadata({
        variables: {
          _eq: props.id,
          name: updatedData["name"],
          description: updatedData["description"],
          recurrance_start_date: updatedData["startDate"],
          recurrance_end_date: updatedData["endDate"],
          location: updatedData["location"],
          type_id: updatedData["eventTypeId"],
          category_id: updatedData["eventCatId"],
          start_time: updatedData["startTime"]?updatedData["startTime"]:props.startTime,
          end_time: updatedData["endTime"]?updatedData["endTime"]:props.endTime,
          cover_img_path: isUploaded ? cover_img_path : props.image,
          conven_meeting:vcMode,
          metadata: {
            max_attendance: updatedData["attendanceCapacity"],
            duration: updatedData["duration"],
          },
        },
      });
      // console.log("instered group header info", data);
      if(updatedData.endDate !== props.edate){
        // calculaterec(new Date(data?.endDate))
      
        handleEndDateChange(new Date(props.edate));
      // console.log("getting this rightobject",props.edate)
      }
      // console.log("event configurations data", UpdateEventMetadataData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

    const [oldEndDate, setOldEndDate] = useState(new Date(props.edate));
  const [newEndDate, setNewEndDate] = useState(new Date(updatedData.endDate));
  const [dayDifference, setDayDifference] = useState(0);
  let cltdtedfnce = '';
  const [recstoptest , setRecstoptest]=useState(false)
  const handleEndDateChange = (newDate) => {
    // Calculate day difference when the end date changes
    const today = new Date();
    // console.log(today,"todays date",newDate)
    const timeDifference =   newDate.getTime() - today.getTime();
    const calculatedDayDifference = timeDifference / (1000 * 3600 * 24);
    cltdtedfnce= timeDifference / (1000 * 3600 * 24);
    // console.log("date difference in fun", calculatedDayDifference,cltdtedfnce);
    tempCalfunc(calculatedDayDifference);
    // setDayDifference(cltdtedfnce);
    setNewEndDate(newDate);
    setOldEndDate(oldEndDate);
    setRecstoptest(true)
  };

  const tempCalfunc = (calculatedDayDifference) =>{
    const today = new Date();
    // console.log( "inside that use effect now");
    for (let i = 0; i < calculatedDayDifference; i++) {
      const dateToPrint = new Date(today);
      dateToPrint.setDate(today.getDate() + i);
      const formattedDate = dateToPrint.toISOString().split('T')[0];
      // console.log("Date:", formattedDate, "Day Difference:", i);
      setDayDifference(i);
      deleterecurringevnt(formattedDate,i)
    }
  }

  // useEffect(() => {
  //   // Print the dates with increasing order based on dayDifference
  //   const today = new Date();
  //   console.log( "inside that use effect now");

  //   for (let i = 0; i < dayDifference; i++) {
  //     const dateToPrint = new Date(today);
  //     dateToPrint.setDate(today.getDate() + i);
  //     const formattedDate = dateToPrint.toISOString().split('T')[0];
  //     console.log("Date:", formattedDate, "Day Difference:", i);
  //     deleterecurringevnt(formattedDate)
  //   }
  // }, [recstoptest]);

  // const todayrequired = new Date();
  const daterequired = new Date(updatedData.endDate);
  daterequired.setDate(daterequired.getDate() - 1)
// console.log("this date ids mnpotr ksnfd coming soon", data.endDate)
    const deleterecurringevnt = async (formattedDate,i) => {
      // console.log("Date inside recurring event:", formattedDate,daterequired.toISOString().split('T')[0]);
      try {
      const { data: insertedData } = await deleterecurringevent({
        variables: {
          _eq:formattedDate,
          _eq1:formattedDate,
          _eq2: props.id,
          // publish_status: "published",
        },
      });
      // console.log("instered group header info", formattedDate,daterequired.toISOString().split('T')[0]);
    if(formattedDate ===daterequired.toISOString().split('T')[0] ){
      handleInsertQuery(i)
      // console.log("yes now true")
    }
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

  // if(recstoptest===true){
  // setRecstoptest(false)

  // }
  const handleInsertQuery = async (iday) => {
    // console.log("handleInsertQuery is called",);
    const startDate = new Date(startt);
    const formattedDate = startDate.toISOString().slice(0, 10);
    // console.log("formatted date:", formattedDate);
    const endDate = new Date(updatedData.endDate);
    const formattedEndDate = endDate.toISOString().slice(0, 10);
    // console.log("dateeeeeeee",formattedEndDate);
    const dateDifference = dayDifference;
    // console.log("datedifference last for inserting", iday);

    for (let i = 1; i <= iday; i++) {
      // console.log("value of i ", i);
      let currentDate = new Date(formattedDate);
      // console.log("current date", currentDate);
      if (updatedData["eventRecFeq"] === "Daily" ) {
        currentDate = addDays(currentDate, i);
      } else if (updatedData["eventRecFeq"] === "Weekly" ) {
        currentDate = addWeeks(currentDate, i);
        // console.log("freq", updatedData["eventRecFeq"]);
        // console.log("current date", currentDate);
      } else if (updatedData["eventRecFeq"] === "Monthly" ) {
        currentDate = addMonths(currentDate, i);
        // console.log("freq", updatedData["eventRecFeq"]);
        // console.log("current date", currentDate);
      } else if (updatedData["eventRecFeq"] === "Yearly" ) {
        currentDate = addYears(currentDate, i);
        // console.log("freq", updatedData["eventRecFeq"]);
        // console.log("current date", currentDate);
      } else {
        break;
      }
      if (currentDate > formattedEndDate) {
        break;
      }
  
      const dateC = format(currentDate, "yyyy-MM-dd")
      // console.log("dateC", dateC)
      handleInsertQuery1(dateC );
      // console.log("inserted record event header");
    }
  };
  // console.log("now this is error time",data["endTime"],data.endTime,"*************",)

  const handleInsertQuery1 = async (dateC , eid) => {
    // console.log("inside recurring event for inserting" ,)
    // const formattedStartTime = data["startTime"].toLocaleTimeString("en-US", {
    //   hour12: false,
    // });
    // const formattedEndTime = data["endTime"].toLocaleTimeString("en-US", {
    //   hour12: false,
    // });
    // console.log("now this is error time",data["endTime"],data.endTime,"*************",)
    const starttimes = updatedData["startTime"] && updatedData["startTime"] !== props.startTime
    ? updatedData["startTime"].toLocaleTimeString("en-US", { hour12: false })
    : props.startTime;

// Check if updatedData["endTime"] exists and is different from props.endTime
const endtimes = updatedData["endTime"] && updatedData["endTime"] !== props.endTime
    ? updatedData["endTime"].toLocaleTimeString("en-US", { hour12: false })
    : props.endTime;
    try {
      const { data: insertedRecData } = await insertRecEventHeaderInfo({
        variables: {
          parent_id:props.id,
          description: updatedData["description"]
          ? updatedData["description"]
          : updatedData?.description,
          name: updatedData["name"]
          ? updatedData["name"]
          : updatedData?.name,
          recurrance_start_date: dateC,
          recurrance_end_date: dateC,
          recurrance_frequency: updatedData["eventRecFeq"] || "",
          category_id: updatedData["eventCatId"]
          ? updatedData["eventCatId"]
          : updatedData?.eventCatId,
          type_id: updatedData["eventTypeId"]
          ? updatedData["eventTypeId"]
          : updatedData?.eventTypeId,
          location:  updatedData["location"]
          ? updatedData["location"]
          : updatedData?.location,
          is_recurring: false,
          start_time: starttimes,
          end_time: endtimes,
          publish_status: "published",
          cover_img_path: isUploaded ? cover_img_path : props.image,
        },
      });
      // console.log("instered event header info", insertedRecData);
    } catch (error) {
      console.error("GraphQL Mutation Error rec:", error.message);
    }
  };
  const [eventCat, setEventCat] = React.useState([]);
  const [eventType, setEventType] = React.useState([]);
  const [formData, setFormData] = useState({});
  const [facilitatorRole, setfacilitatorRole] = useState([]);
  const [staff, setStaff] = useState([]);

  //............................FETCH EVENT TYPE & CATEGORY................................
  const {
    loading: eventTypeLoading,
    error: eventTypeError,
    data: eventTypeData,
  } = useQuery(GET_EVENT_TYPE);

  const {
    loading: eventCatLoading,
    error: eventCatError,
    data: eventCatData,
  } = useQuery(GET_EVENT_CATEGORY);

  useEffect(() => {
    setEventType(eventTypeData?.event_type);

    // console.log("inside use effect event type", eventTypeData?.event_type);
    setEventCat(eventCatData?.event_category);
  }, [eventTypeData, eventCatData]);
  // console.log("event category category map", eventCatData?.event_category);

  // console.log("date coming from prop is", props.recurrance_start_date);

  const handleChange = (key, value) => {
    // console.log(key, value);
    setdata({
      ...data,
      [key]: value,
    });
  };


  // console.log("updated data", props);
//   console.log("updatedData event_category:", updatedData.event_category);
// console.log("props event_category:", props.event_category);


  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    // console.log(formattedDate);
    setUpdatedData((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };

  const handleTimeChange = (key, value) => {
    let formattedTime = value.format("hh:mm a");
    // console.log(formattedTime);
    setUpdatedData((prevData) => {
      return {
        ...prevData,
        startTime:props.startTime,
        endTime:props.endTime,
        [key]: formattedTime,
      };
    });
  };
  // ................................ update cover image ..................................................................

  const nhost = useNhostClient();
  const [image, setImage] = React.useState(null);
  const [coverImageId, setCoverImageId] = React.useState("");
  const changeImg = () => {
    const confirmation = window.confirm(
      `Do you really want to change Cover Image?`
    );
    if (confirmation) {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";

      input.onchange = async (event) => {
        nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

        let image = event.target.files[0];
        setImage(URL.createObjectURL(image));

        let file = event.target.files[0];
        setCoverImageId(file.name);
        await upload({ file });
      };
      input.click();
    }
  };

  // ................................ update cover image ..................................................................

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}></Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
      >
        <Box
          sx={{
            height: 50,
            width: "100%",
            backgroundColor: "#005e89",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            style={{ marginLeft: 25 }}
            src={require("../../../images/navLogo.png")}
          />
          <Button onClick={handleClose}>
            <Typography sx={{ color: "white" }}>Close</Typography>{" "}
          </Button>
        </Box>

        {/* Group Category */}

        {/* .............................................................................................. */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              ml: "1%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* ......................event type......................... */}
            <TextField
              sx={{
                width: "360px",
                mt: 2,
                marginLeft: 1,
                mb: 1,
                fontFamily: "Montserrat",
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                },
                "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",
                  color: " #999999",
                  fontSize: "14px",
                  lineHeight: " 20px",
                },
              }}
              required
              size="small"
              value={updatedData.name}
              placeholder={updatedData.name}
              onChange={(e) => {
                setUpdatedData((prevData) => ({
                  ...prevData,
                  name: e.target.value,
                }));
              }}
              // error={!!errors.location}
              // helperText={errors.location}
              id="standard-required"
              label="Event Name"
            />

            <Box sx={{ ml: "7px" }}>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                size="small"
                rows={3.5}
                value={updatedData.description}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    description: e.target.value,
                  }));
                }}
                sx={{
                  width: "360px",
                  mt: 2,
                  marginLeft: 0,
                  mb: 1,
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                  },
                  "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: " #999999",
                    fontSize: "14px",
                    lineHeight: " 20px",
                  },
                }}
              />
            </Box>

            <Box sx={{ m: 1 }}>
              <TextField
                label="Attendance Capacity"
                size="small"
                sx={{
                  width: "360px",
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                  },
                  "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: " #999999",
                    fontSize: "14px",
                    lineHeight: " 20px",
                  },
                }}
                id="outlined"
                value={updatedData.attendanceCapacity}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    attendanceCapacity: e.target.value,
                  }));
                }}
              />
            </Box>

            <Box sx={{ m: 1 }}>
              <TextField
                label="Duration"
                size="small"
                sx={{
                  width: "360px",
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                  },
                  "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: " #999999",
                    fontSize: "14px",
                    lineHeight: " 20px",
                  },
                }}
                id="outlined"
                value={updatedData.duration}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    duration: e.target.value,
                  }));
                }}
              />
            </Box>

            <Box>
              <FormControl
                sx={{ width: "360px", m: 1, fontFamily: "MontserratRegular" }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    fontSize: "14px",
                  }}
                  size="small"
                >
                  Class Type
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="eventcatype"
                  placeholder={updatedData.event_type}
                  value={updatedData?.eventTypeId}
                  onChange={(e) => {
                    setUpdatedData((prevData) => ({
                      ...prevData,
                      eventTypeId: e.target.value,
                    }));
                  }}
                  size="small"
                  sx={{ fontFamily: "MontserratRegular" }}
                >
                  {eventType?.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl
                sx={{ width: "360px", m: 1, fontFamily: "MontserratRegular" }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    fontSize: "14px",
                  }}
                  size="small"
                >
                  Event Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // placeholder={
                  //   updatedData.event_category
                  //     ? updatedData.event_category
                  //     : props?.event_category
                  // }
                  value={updatedData?.eventCatId}
                  label="eventcategory"
                  onChange={(e) => {
                    setUpdatedData((prevData) => ({
                      ...prevData,
                      eventCatId: e.target.value,
                    }));
                  }}
                  size="small"
                  sx={{ fontFamily: "MontserratRegular" }}
                >
                  {eventCat?.map((item, index) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ m: 1 }}>
                <Typography>Start Date</Typography>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      size="small"
                      label={props.sdate}
                      inputFormat="YYYY/MM/DD"
                      format="YYYY/MM/DD"
                      sx={{
                        width: "360px",
                        "& .MuiInputBase-root": {
                          fontFamily: "MontserratRegular",
                          height: "40px",
                        },
                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                          fontFamily: "MontserratRegular",
                          color: "#999999",
                          fontSize: "14px",
                          lineHeight: "10px",
                        },
                      }}
                      value={data.startDate}
                      onChange={(e) => {
                        handleDateChange("startDate", e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          placeholder="Class Name"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ m: 1 }}>
                <Typography>Start Time</Typography>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      size="Start Time"
                      label={
                        props.startTime
                          ? props.startTime
                          : "Enter new start time"
                      }
                      placeholder={props.startTime}
                      ap={true}
                      format="h:mm A "
                      sx={{
                        width: "360px",
                        mt: "15px",
                        "& .MuiInputBase-root": {
                          fontFamily: "MontserratRegular",
                          height: "40px",
                        },
                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                          fontFamily: "MontserratRegular",
                          color: "#999999",
                          fontSize: "14px",
                          lineHeight: "10px",
                        },
                      }}
                      value={data.startTime}
                      onChange={(e) => {
                        handleTimeChange("startTime", e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          placeholder="Event Name"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]{2}:[0-9]{2} [AaPp][Mm]",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </Box>
            </Box>

            {/* Attendance Capacity */}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Typography>End Date</Typography>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      // inputFormat="MM/DD/YYYY"
                      format="YYYY/MM/DD"
                      inputFormat="YYYY/MM/DD"
                      size="small"
                      label={props.edate}
                      sx={{
                        mt: 1.5,
                        width: "360px",
                        "& .MuiInputBase-root": {
                          fontFamily: "MontserratRegular",
                          height: "40px",
                        },
                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                          fontFamily: "MontserratRegular",
                          color: "#999999",
                          fontSize: "14px",
                          lineHeight: "10px",
                        },
                      }}
                      onChange={(e) => {
                        handleDateChange("endDate", e);
                      }}
                      value={data.endDate}
                      // error={!!errors.endDate}
                      // helperText={errors.endDate}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          placeholder="Event Name"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </Box>

              {/* <Box sx={{ml:1}}> */}
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ m: 1 }}>
                <Typography>End Time</Typography>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      size="End Time"
                      // label="Enter new end time"
                      label={
                        props.endTime
                          ? props.endTime
                          : "Enter new end time"
                      }
                      ap={true}
                      format="h:mm A "
                      sx={{
                        width: "360px",
                        mt: "15px",
                        "& .MuiInputBase-root": {
                          fontFamily: "MontserratRegular",

                          height: "40px",
                        },

                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                          fontFamily: "MontserratRegular",

                          color: "#999999",

                          fontSize: "14px",

                          lineHeight: "10px",
                        },
                      }}
                      value={data.startTime}
                      onChange={(e) => {
                        handleTimeChange("endTime", e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          placeholder="Event Name"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]{2}:[0-9]{2} [AaPp][Mm]",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </Box>
            </Box>
{recurrance_frequency && (

            <Box>
              <FormControl
                sx={{ width: "360px", m: 1, fontFamily: "MontserratRegular" }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    fontSize: "14px",
                  }}
                  size="small"
                >
                  Recurrence Frequency
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={updatedData?.eventRecFeq}
                  label="Recurrence Frequency"
                  onChange={(e) => {
                    setUpdatedData((prevData) => ({
                      ...prevData,
                      eventRecFeq: e.target.value,
                    }));
                  }}
                  size="small"
                  sx={{ fontFamily: "MontserratRegular" }}
                >
                  {frequency?.map((item, index) => {
                    return <MenuItem value={item.name}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
)}
 <FormControl
        sx={{width:"350px",mt:1, display: "flex", flexDirection: "row", alignItems: "center" }}
      >
      
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={vcMode}
          onChange={(e) => setVcMode(e.target.value === "true")}
          sx={{ width:"400px", margin: 1,"& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
          { color: "#005e89" }, }}
        >
          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "005e89",
              },
              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",
                color: "#999999",
                fontSize: 14,
              },
            }}
            value="true"
            control={<Radio />}
            label="ConveneVC"
          />
          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "005e89",
              },
              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",
                color: "#999999",
                fontSize: 14,
              },
            }}
            value="false"
            control={<Radio />}
            label="Other VC"
          />
        </RadioGroup>
      </FormControl>
        
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                sx={{
                  width: "360px",
                  mt: 2,
                  marginLeft: 1,
                  mb: 1,
                  fontFamily: "Montserrat",
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                  },
                  "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: " #999999",
                    fontSize: "14px",
                    lineHeight: " 20px",
                  },
                }}
                required
                size="small"
                // value={formData.location}
                // onChange={(e) => {
                //   handleChange("location", e.target.value);
                // }}
                // error={!!errors.location}
                // helperText={errors.location}
                id="standard-required"
                label="Location"
                value={updatedData.location}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    location: e.target.value,
                  }));
                }}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {/* <Box sx={{ m: 1 }}> */}
              <Box>
                <Typography>Cover Image</Typography>
              </Box>
              <Box
                onClick={() => changeImg()}
                style={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                {/* <img style={{ width: "80%" }} src={props.cover_img_path} /> */}
                {image ? (
                  <img
                    style={{ maxWidth: "360px", height: "320px" }}
                    src={image}
                    alt="Cover Image"
                  />
                ) : (
                  <img
                    style={{ maxWidth: "360px", height: "320px" }}
                    src={props.urlImg}
                    alt="Cover Image"
                  />
                )}
              </Box>
            </Box>

            <Box
              sx={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                my: 1,
              }}
            >
              <Button
                sx={{
                  width: "30%",
                  backgroundColor: "#EE4036",
                  color: "#FFFFFF",
                  height: 40,
                  margin: 3,
                }}
                variant="conatined"
                onClick={handleSave}
              >
                {" "}
                SAVE
              </Button>
              <Button
                sx={{
                  width: "30%",
                  backgroundColor: "#FFFFFF",
                  color: "#999999",
                  height: 40,
                  border: 1,
                  margin: 3,
                }}
                variant="conatined"
                onClick={handleClose}
              >
                {" "}
                CANCEL
              </Button>
            </Box>
          </Box>
        </Box>
        {/* .............................................................................................. */}

        {/* Duration */}
        {/* Price */}
      </Dialog>
    </div>
  );
}
