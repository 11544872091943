//import * as React from "react";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { addTask } from "../../Redux/features/UserSlice";
import profile from "../../images/Profile.jpg";
import { useNhostClient } from '@nhost/react'


export default function NewFeed(props) {
  const {name} = props;
  // console.log("props show",props)

  const [open, setOpen] = React.useState(false);
  const [drop, setDrop] = React.useState();
  const [drop2, setDrop2] = React.useState();

  const openpop = () => {
    setDrop(true);
    setDrop2(false);
  };
  const openpop2 = () => {
    setDrop2(true);
    setDrop(false);
  };

  const handleClickOpen = () => {
    props.setNewFeedOpen(true);
  };
  const handleClickclose = () => {
    props.setNewFeedOpen(false);
  };
  const [open1, setOpen1] = React.useState(false);


  // .....................fetch image from id .....................
  const nhost = useNhostClient()
  const [imgUrl, setImgUrl] = useState('')
  useEffect(() => {

    async function setImage() {

      // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)
      // nhost.storage.setAccessToken(res1.fileMetadata.id)

      // console.log(cover_img_path)
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: props.img,
        });
        console.log("Image URL:", item);
   setImgUrl(item)  
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }

      // const publicUrl = await nhost.storage.getPresignedUrl({
      //   fileId: props.img,
      // })

      // console.log(publicUrl)
      

    }

    setImage()

  }, [])
  // .....................fetch image from id ends.....................

 


  return (
    <div>
      <Dialog
        maxWidth="xl"
        sx={Styles.popup}
        open={props.open}
        onClose={handleClickclose}
      >
      
        <Box sx={Styles.header}>
          <img
            style={{ width: "79px", height: "25" ,marginLeft:10}}
            src={require("../../images/navLogo.png")}
          />
          <DialogActions>
          <Button onClick={handleClickclose} sx={{font:30,color:'#FFFFFF',}}>Close</Button>
          </DialogActions>
        </Box>
       
        <DialogContent>
       
          <Box sx={Styles.mainBox}>
            <Box sx={Styles.firstBox}>
            <Typography style={Styles.header1}>{props.name}</Typography>
            <img src={imgUrl} style={Styles.img}/>
           
            </Box>
            <Box sx={Styles.secondBox}>
            <Typography style={Styles.header1}>Start Date : {props.sdate}</Typography>
            <Typography style={Styles.header1}>End   Date : {props.edate}</Typography>
            <Box sx={Styles.description}>
            <Typography style={{margin:10,fontFamily:"MontserratRegular"}}>{props.desc}</Typography>
            </Box>
           </Box>

           </Box>
        </DialogContent>
       
      </Dialog>
    </div>
  );
}

const Styles = {
  header: {
    // width:"100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#005e89",
    
  },
  header1:{
    fontFamily: "MontserratSemibold", 
    marginBottom: "10px",
    fontSize:20
  },
  img:{

    height:'80%',
    width:'90%',
    marginTop:30
  },
  popup: {
    borderRadius: "15px",
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems:"center",
    width: "900px",
    height:'350px'

  },
  input: {
    width: "100%",
    marginTop: "18px",
    
    height: "40px",
    // backgroundColor:"#005e89",
    borderRadius: "5px",
    fontFamily: "MontserratRegular",
  },
  firstBox: {
    width: "69%",
    
  },
  secondBox: {
    width: "69%",
  
  },
  description: {
    width: "100%",
    height:"60%",
     marginTop: 5,
    marginBottom: "15px",
    fontFamily: "MontserratRegular",
    border: "1px solid grey",
    overflowY:"scroll"
  },
  fileUpload: {
    marginLeft: "10px",
    backgroundColor: "#D9D9D9",
    border: "1px solid #888888",
    borderRadius: "5px",
    fontFamily: "MontserratRegular",
  },
  date: {
    textAlign: "start",
    width: "100%",
    height: "40px",
    marginTop: "15px",
    color: "#999999",
    background: "#FFFFFF",
    border: " 1px solid #999999",
    borderRadius: "5px",
  },
  assignBtn: {
    // width: "100%",
    height: "40px",
    marginTop: "15px",
    color: "#FFFFFF",
    backgroundColor: "#005e89",
    fontFamily: "MontserratRegular",
  },
};
