import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { React, useEffect } from "react";
import Navbar from "../Navbar";
import { useNavigate } from "react-router";
import FullRedButton from "../../components/AdminFeed/FullRedButton";
import { useDispatch } from "react-redux";
import { addUser } from "../../Redux/features/UserSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFileUpload, useSignUpEmailPassword, useUserData, useUserEmail } from "@nhost/react";
import { useLocation, useRoutes } from "react-router-dom";
import { useNhostClient } from "@nhost/react";
import { AirplanemodeActive } from "@material-ui/icons";
import { useMutation, gql, useQuery } from "@apollo/client";

const ALL_USERS_QUERY = gql`
  query MyQuery13($_eq: String = "unapproved") {
    users(
      where: {
        defaultRole: { _eq: "user" }
        _and: { profile_detail: { category: { _eq: $_eq } } }
      }
    ) {
      displayName
      email
      phoneNumber
      id
      metadata
      profile_detail {
        address1
        address2
        bio
        birth_date
        category
        city
        country
        province
        first_name
        postcode
        last_name
        gender
        profile_pic_path
      }
    }
  }
`;

const GET_EXISTING_USER_QUERY = gql`
  query getAllUserRequests {
    profile_details(where: { category: { _neq: "unapproved" } }) {
      bio
      birth_date
      category
      first_name
      last_name
      profile_pic_path
      second_name
      city
      province
      user_id
      address1
      address2
      gender
      country
      postcode
      user {
        defaultRole
        displayName
        email
        emailVerified
        id
        phoneNumber
      }
    }
  }
`;
const INSERT_USER_SIGNUP = gql`
  mutation CreateNewUser(
    $defaultRole: String
    $displayName: String
    $email: citext
    $phoneNumber: String
    $locale: String
    $passwordHash: String
    $avatarUrl: String
  ) {
    insertUsers(
      objects: {
        defaultRole: $defaultRole
        displayName: $displayName
        email: $email
        phoneNumber: $phoneNumber
        locale: $locale
        passwordHash: $passwordHash
        avatarUrl: $avatarUrl
      }
    ) {
      returning {
        defaultRole
        email
        displayName
        id
        locale
        passwordHash
        phoneNumber
        avatarUrl
      }
    }
  }
`;

const INSERT_ADD_USER_TO_PROFILE = gql`
  mutation AddUserToProfile(
    $user_id: uuid
    $first_name: String
    $birth_date: date
    $city: String
    $category: String
    $postcode: String
    $address1: String
    $address2: String
    $bio: String
    $roles: String
    $province: String
    $country: String
    $gender: String

  ) {
    insert_profile_details(
      objects: {
        user_id: $user_id
        first_name: $first_name
        birth_date: $birth_date
        city: $city
        category: $category
        postcode: $postcode
        address1: $address1
        address2: $address2
        bio: $bio
        roles: $roles
    province: $province
    country: $country
    gender: $gender

      }
    ) {
      returning {
        user_id
        first_name
        birth_date
        city
        category
        postcode
        address1
        address2
        bio
        roles
        gender
      }
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $user_id: uuid
    $bio: String
    $birth_date: date
    $category: String
    $first_name: String
    $last_name: String
    $address1: String
    $address2: String
    $profile_pic_path: String = ""
    $second_name: String
    $city: String
    $province: String
    $country: String
    $gender: String
    $postcode: String
    $roles: String
  ) {
    update_profile_details(
      where: { user_id: { _eq: $user_id } }
      _set: {
        bio: $bio
        birth_date: $birth_date
        category: $category
        first_name: $first_name
        last_name: $last_name
        profile_pic_path: $profile_pic_path
        second_name: $second_name
        address2: $address2
        address1: $address1
        city: $city
        province: $province
        country: $country
        gender: $gender
        postcode: $postcode
        roles: $roles
      }
    ) {
      returning {
        user_id
        second_name
        last_name
        first_name
        category
        birth_date
        bio
        address1
        address2
        city
        province
        country
        profile_pic_path
        gender
        postcode
        roles
      }
    }
  }
`;

const UPDATE_MEMBER_CATEGORY = gql`
  mutation updateUser(
    $defaultRole: String = ""
    $_eq: uuid = ""
    $emailVerified: Boolean = true
  ) {
    updateUsers(
      where: { id: { _eq: $_eq } }
      _set: { defaultRole: $defaultRole, emailVerified: $emailVerified }
    ) {
      returning {
        defaultRole
      }
    }
  }
`;
const UPDATE_USERTABLE= gql`
mutation updateusertable($avatarUrl: String = "", $displayName: String = "", $_eq: uuid = "" ,$defaultRole: String) {
  updateUsers(where: {id: {_eq: $_eq}}, _set: {avatarUrl: $avatarUrl, displayName: $displayName,defaultRole: $defaultRole }) {
    returning {
      avatarUrl
      defaultRole
      email
    }
  }
}
`;

const GET_ALL_ROLES = gql`
  query getallroles($_eq: String = "true") {
    role_master(where: { status: { _eq: $_eq } }) {
      access_name
      description
      roles
      id
      status
    }
  }
`;

export default function AddUser() {
  // const [image, setImage] = useState(null);
  const {
    add,

    upload,

    cancel,

    isUploaded,

    isUploading,

    isError,

    progress,

    id: cover_img_path,

    bucketId,

   
  } = useFileUpload();
  const location = useLocation();
  const uodateUserId = location?.state?.id;
  // console.log("Staff needed", location.state?.rows.category);
  const isStaff = location?.state?.rows?.category;
  // console.log('we finally got states and ids ',uodateUserId)

  const [existUser, setExistingUser] = useState([]);
  const [existUsers, setExistUsers] = useState([]);

  const { data: existing_user, refetch } = useQuery(GET_EXISTING_USER_QUERY);
  const { data: userData, refetch: refetch1 } = useQuery(ALL_USERS_QUERY);

  // console.log("add data",existing_user);

  //  console.log("the data we want",existing_user?.profile_details)
  useEffect(() => {
    setExistingUser(existing_user?.profile_details);
  }, [existing_user]);
  const [updatedData, setUpdatedData] = useState(() => {
    const apiData = location.state?.rows; // Assuming API response is stored in location.state.rows
    console.log("apiData", apiData);
    if (apiData) {
      return {
        displayName: apiData.user?.displayName || apiData.displayName,
        bio: apiData.bio || apiData?.profile_detail?.bio,
        birthDate: apiData.birth_date || apiData?.profile_detail?.birth_date,
        email: apiData.user?.email || apiData.email,
        phoneNumber: apiData.user?.phoneNumber || apiData.phoneNumber,
        add1: apiData.address1
          ? apiData.address1
          : apiData?.profile_detail?.address1,
        add2: apiData.address2 || apiData?.profile_detail?.address2,
        city: apiData.city || apiData?.profile_detail?.city,
        memberCategory: apiData.category || apiData?.profile_detail?.category,
        state: apiData.province || apiData?.profile_detail?.province,
        country: apiData.country || apiData?.profile_detail?.country,
        postcode: apiData.postcode || apiData?.profile_detail?.postcode,
        gender: apiData.gender || apiData?.profile_detail?.gender,
        roles:apiData.roles || apiData?.profile_detail?.roles
        // profilePic: apiData.profile_pic_path || "",
      };
    } else {
      return {
        displayName: "",
        bio: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        add1: "",
        add2: "",
        city: "",
        memberCategory: "",
        state: "",
        country: "",
        postcode: "",
        gender: "",
        roles:""
        // profilePic:"",
      };
    }
  });
  const nhost = useNhostClient();


  // console.log("updated data", updatedData);
  useEffect(() => {
    const filteredUsers = userData?.users;

    setExistUsers(filteredUsers);
    // console.log("user request",user_request?.users)
  }, [userData]);
  const [insertUserSignup] = useMutation(INSERT_USER_SIGNUP, {
    onCompleted: (data) => {
      // console.log("passing data to ", data.insertUsers);
      addUserToProfile(
        data.insertUsers.returning[0].id,
        data.insertUsers.returning[0].displayName
      );
      refetch();
      refetch1();
    },
    onError: (error) => {
      console.log(error, "this is the error");
      setEmailError(true);
      // alert("This Email or Phone Number is already used")

    },
    refetchQueries: [
      // Provide the name of the query you want to refetch after deletion
      // For example:
      { query: GET_EXISTING_USER_QUERY },
      { query: ALL_USERS_QUERY },
    ],
  });
  const [roledata, setRoleData] = useState([]);
  const { data: user } = useQuery(GET_ALL_ROLES, {
    onCompleted: (data) => {
      console.log("all data roles", data?.role_master);
      setRoleData(data?.role_master);
    },
  });
  const [emailerror, setEmailError] = useState(false);
  const [insertAddtoProfile] = useMutation(INSERT_ADD_USER_TO_PROFILE, {
    onerror: (error) => {
      console.log("error while updating data", error);
      setEmailError(true);
    },
    onCompleted: (data) => {
      handleUser();

      // console.log("first Add to profile success", data);
      // console.log(
      //   "first Add to Profile success",
      //   data.insert_profile_details.returning[0].user_id
      // );

      // profileUpdates(data.insertUsers.returning[0].id)
      profileUpdates(data.insert_profile_details.returning[0].user_id);
      refetch();
      refetch1();
    },
  });

  // Function to refetch GET_EXISTING_USER_QUERY

  const [updateUserProfile, { error , data:profuptdt }] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: () => {
      refetch();
      refetch1();
    },
    refetchQueries: [
      { query: GET_EXISTING_USER_QUERY },
      { query: ALL_USERS_QUERY },
    ],
  });
  const [updateMemberProfile] = useMutation(UPDATE_MEMBER_CATEGORY,{
    // onCompleted:
  });
  const [updateusertable] = useMutation(UPDATE_USERTABLE);

  const [isChecked, setIsChecked] = useState(false);
  // console.log(error, "errorrr ");
  const navigate = useNavigate();
  function handleUser() {
    navigate("/usermanagement", { newUserAdded: true });
  }
  // console.log("isStaff sdzcjbadkajdkajdhkj:", isStaff);

  useEffect(() => {
    if (isStaff === "staff") {
      setIsChecked(true);
      setRole("Staff");
    } else {
      setIsChecked(false);
      setRole("user");
    }
  }, [isStaff]);
  const userEmail = useUserEmail()

  const [role, setRole] = useState("user");
  // console.log("role: ", role);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setRole("Staff");
    } else {
      setRole("user");
    }
    // console.log(event.target.checked);
  };

  // const {id, email ,phoneNumber} = location.state
  // console.log(route.params.isForUpdate);
  const [update, setUpdate] = useState(false);
  // if(location.state.isForUpdate){
  //   setUpdate(true)
  // }

  const [temp1, setTemp1] = useState(false);
  useEffect(() => {
    if (location.state?.isForUpdate) {
      // console.log("new values are", location.state.rows);
      setUpdate(true);
      // console.log("sd", temp1);
    }
  }, []);
  const [memtest , setmemtest]=useState(true)

  if(location.state?.rows?.profile_detail?.category==='unapproved' && profuptdt?.update_profile_details?.returning[0]?.category!=='unapproved' && memtest){
    sendemail()
  }
      async function sendemail(){
      try {
        await nhost.auth.sendVerificationEmail({ email: 'prasanna@convenevc.com' });
        console.log('Verification email sent successfully');
      } catch (error) {
        console.error('Error sending verification email:', error);
      }   console.log('mail checking2')
      // console.log(" signup error", error, isError);
      setmemtest(false)
    }
  const addUserToProfile = async (id, display) => {
    // console.log("in the add user profile function",id , display, updatedData["birthDate"],updatedData["city"],updatedData["memberCategory"],updatedData["pincode"],updatedData["add1"],updatedData["add2"],updatedData["bio"])
    try {
      const { data } = await insertAddtoProfile({
        variables: {
          user_id: id,
          first_name: display,
          birth_date: updatedData["birthDate"],
          city: updatedData["city"],
          category: !isChecked ? updatedData["memberCategory"] : "staff",
          postcode: updatedData["postcode"],
          address1: updatedData["add1"],
          address2: updatedData["add2"],
          bio: updatedData["bio"],
          roles: isChecked ? updatedData["roles"] : "",
          province:updatedData["state"],
          country: updatedData["country"],
          gender: updatedData["gender"],

        },
      });
      //  console.log("last",updatenew)
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };
  const [smemtest , setsmemtest]=useState(true)
 
// if(smemtest){
//   // await nhost.auth.sendVerificationEmail({ email: 'prasanna@convenevc.com' });
//   sendemailtest()
//   console.log("hello email testing here :")
//   setsmemtest(false)
// }
async function sendemailtest(){
  try {
    await nhost.auth.sendVerificationEmail({ email: 'prasannajoshi3511@gmail.com' });
//  await nhost.auth.changeEmail({ newEmail: userEmail })
  // await nhost.auth.resetPassword({ email: 'prasannajoshi3511@gmail.com' })

    console.log('Verification email sent successfully');
  } catch (error) {
    console.error('Error sending verification email:', error);
  }   console.log('mail checking2')
  // console.log(" signup error", error, isError);
  setmemtest(false)
}
  const profileUpdates = async (user_id) => {
    // console.log("user is update   ;;",user_id)
    try {
      const { data } = await updateUserProfile({
        variables: {
          user_id: uodateUserId,
          first_name: updatedData["displayName"],
          birth_date: updatedData["birthDate"],
          bio: updatedData["bio"],
          address1: updatedData["add1"],
          address2: updatedData["add2"],
          category: isChecked?"staff":updatedData["memberCategory"],
          city: updatedData["city"],
          province: updatedData["state"],
          country: updatedData["country"],
          profile_pic_path: image
            ? coverImageId
            : location.state?.rows?.profile_pic_path,
          gender: updatedData["gender"],
          postcode: updatedData["postcode"],
          roles: isChecked? updatedData["roles"] : location.state?.rows?.roles,
        },
      });
      navigate("/usermanagement", { newUserAdded: true });

      //  console.log("last",updatenew)
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

  // signup starts

  const userUpdate = async (id) => {
    // console.log("id for update:", id,location.state.id);
    try {
      const { data } = await updateMemberProfile({
        variables: {
          _eq: location.state.id,
          defaultRole: role,
        },
      });
      //  console.log("last",updatenew)
    } catch (error) {
      // console.error('GraphQL Mutation Error:', error.message);
    }
    // updateusertable
    try {
      const { data } = await updateusertable({
        variables: {
          _eq: location.state.id,
          avatarUrl: cover_img_path?cover_img_path:location?.state?.rows?.user?.avatarUrl,
          displayName:updatedData["displayName"]?updatedData["displayName"]:'',
          defaultRole: isChecked?"staff":"user"

        },
      });
      //  console.log("last",updatenew)
    } catch (error) {
      // console.error('GraphQL Mutation Error:', error.message);
    }
  };

  const [defRole, setDefRole] = useState("");
  const signup = async () => {
    // console.log(role,'this is role')
    const names = "";
    if (role === "Staff") {
      setDefRole("staff");
      // names='staff'
    }

    // console.log(names,'this is def role')

    try {
      const { data } = await insertUserSignup({
        variables: {
          displayName: updatedData["displayName"],
          email: updatedData["email"],
          defaultRole: !isChecked ? "user" : "staff",
          passwordHash:
            "$2a$10$k7J.qE1n4zVw9vrl0sRThe2SMoTZ9qKV.kOlxYFBHFVqyYNk0iUiO",
          phoneNumber: updatedData["phoneNumber"],
          locale: "en",
          avatarUrl:cover_img_path?cover_img_path:""
        },
      });
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
    //

    // addUserToProfile(user_id, data1.data.insertUsers.returning[0].displayName);
  };

  const handleNav = async (id) => {
    // console.log("pasha",data.id)

    // }

    const newErrors = {};

    // Validate name
    if (!updatedData.displayName) {
      newErrors.displayName = "Name is required";
    }
    if (emailerror) {
      newErrors.email = "This Email is already in use";
    }
    // Validate email
    if (!updatedData.email) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(updatedData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!updatedData.phoneNumber) {
      newErrors.phoneNumber = "Number is required";
    }
    if (!updatedData.city) {
      newErrors.city = "City is required";
    }
    if (!updatedData.postcode) {
      newErrors.postcode = "Pincode is required";
    }
    if (!updatedData.state) {
      newErrors.state = "State is required";
    }
    if (!updatedData.country) {
      newErrors.country = "Country is required";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (update) {
        userUpdate();
        profileUpdates();
      } else {
        // console.log('not update direct insert')
        signup();
      }
    }
  };
  const [date, setDate] = useState(new Date());
  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD HH:mm:ss");
    // console.log(formattedDate);
    setUpdatedData((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
    handleChange("birthDate", formattedDate);
  };

  const [data, setData] = useState(temp1 ? location.state.row : {});

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (location.state?.isForUpdate) {
      // console.log("new values are", location.state.users);
      // setTemp1(true)
      setData(location.state.rows.users);
      // console.log("sd", temp1);
      // console.log("omkar test", data);
    } else {
    }
  });

  const [imgUrl, setImgUrl] = useState(null);
  const [img, setImg] = useState(null);
  useEffect(() => {
  
    async function setImage() {

      // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)
      // nhost.storage.setAccessToken(res1.fileMetadata.id)

      // console.log(cover_img_path)
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: location?.state?.rows?.user?.avatarUrl,
        });
        console.log("Image URL:", item);
  // setImage(item)
  // setImgUrl(item);
  setImgUrl(item)


  
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }

      // const publicUrl = await nhost.storage.getPresignedUrl({
      //   fileId: props.img,
      // })

      // console.log(publicUrl)

    }

    setImage()

  }, [])
  // console.log("updated data", updatedData);
  const handleChange = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    console.log(key, value);
  };

  function isValidEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  // ........................upload new image................................
  const [image, setImage] = useState(null);
  const [coverImageId, setCoverImageId] = useState("");

  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
      nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

      let file = event.target.files[0];
      setImage(URL.createObjectURL(file));
      console.log("feed image upload link",file)
      // setImage(URL.createObjectURL(file))
      await upload({ file });
      // const fd = new FormData();

      // fd.append("file", image);

      // let res1 = await nhost.storage.upload({ formData: fd });

      // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);
      // nhost.storage.setAccessToken(res1.fileMetadata.id);
      // const accessToken = nhost.auth.getAccessToken();

      // const publicUrl = await nhost.storage.getPresignedUrl({
      //   fileId: res1.fileMetadata.id,
      // });

      // // setImage(publicUrl.presignedUrl.url);

      // setCoverImageId(res1.fileMetadata.id);

      // console.log("image",publicUrl.presignedUrl.url);
    };
    input.click();
  };
  const birthDate = new Date(updatedData.birthDate);
const formattedBirthDate = birthDate.toLocaleDateString('en-GB'); 
  // .............................upload new image end .......
  // if(isUploaded){
  //   console.log('coverimage',cover_img_path)
  // }
  return (
    <Box sx={{ height: "100%", overflowX: "hidden" }}>
      <Navbar />
      <Box sx={Styles.topContainer}>
        <Typography
          sx={[Styles.largeTxt, { cursor: "pointer" }]}
          onClick={handleUser}
        >
          User Management - Add User
        </Typography>
      </Box>
      <Box sx={Styles.mainBox}>
        <Box sx={Styles.firstBox}>
          <Box sx={Styles.headerBox}>
            <Typography sx={Styles.headerText}>Add User</Typography>
          </Box>
          <Box sx={Styles.secondBox}>
            <Box sx={Styles.inner1}>
              <Typography sx={Styles.headerText}>Personal Details</Typography>
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Name"
                required
                variant="outlined"
                value={updatedData.displayName}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    displayName: e.target.value,
                  }));
                }}
                error={!!errors.displayName}
                helperText={errors.displayName}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="YYYY/MM/DD"
                  size="small"
                  label={formattedBirthDate}
                  sx={[
                    Styles.textField,
                    {
                      // width: "360px",
                      "& .MuiInputBase-root": {
                        fontFamily: "MontserratRegular",
                        height: "40px",
                      },
                      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                        fontFamily: "MontserratRegular",
                        color: "#999999",
                        fontSize: "14px",
                        lineHeight: "10px",
                      },
                    },
                  ]}
                  value={date.birthDate}
                  onChange={(e) => {
                    handleDateChange("birthDate", e);
                  }}
                />
              </LocalizationProvider>
              <TextField
                id="filled-multiline-static"
                placeholder="Bio"
                multiline
                rows={3}
                sx={Styles.textField}
                value={updatedData.bio}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    bio: e.target.value,
                  }));
                }}
              />
              {!isChecked && (
                <>
                  <Typography sx={[Styles.headerText, { marginTop: "0px" }]}>
                    Configure User
                  </Typography>
                  <FormControl sx={Styles.textField}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      InputLabelProps={{
                        sx: {
                          fontFamily: "MontserratRegular",
                        },
                      }}
                    >
                      Member Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={data.category}
                      label="Member Category"
                      size="small"
                      // multiple
                      value={updatedData.memberCategory}
                      onChange={(e) => {
                        setUpdatedData((prevData) => ({
                          ...prevData,
                          memberCategory: e.target.value,
                        }));
                      }}
                    >
                      {[
                        "Lead",
                        "Prospect",
                        "Suspect",
                        "Member",
                        "unapproved",
                      ].map((item, index) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
              {isChecked && (
                <>
                  <Typography sx={[Styles.headerText, { marginTop: "0px" }]}>
                    Assign Role
                  </Typography>
                  <FormControl sx={Styles.textField}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      InputLabelProps={{
                        sx: {
                          fontFamily: "MontserratRegular",
                        },
                      }}
                    >
                      Assign Roles
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={data.category}
                      label="Assign Roles"
                      size="small"
                      // multiple
                      value={updatedData.roles}
                      onChange={(e) => {
                        setUpdatedData((prevData) => ({
                          ...prevData,
                          roles: e.target.value,
                        }));
                      }}
                    >
                      {roledata.map((item, index) => {
                        return (
                          <MenuItem value={item.access_name}>
                            {item.access_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>

            {/* {emailerror && 
            <Typography style={{color:"red",fontFamily:"MontserratRegular"}}>
              Email or Phone number already exists
            </Typography>
            } */}

            <Box sx={Styles.inner2}>
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Email ID"
                required
                variant="outlined"
                value={updatedData.email}
                disabled={update}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    email: e.target.value,
                  }));
                }}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Mobile Number"
                // type="number"
                required
                variant="outlined"
                disabled={update}
                value={updatedData.phoneNumber}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    phoneNumber: e.target.value,
                  }));
                }}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
              <RadioGroup
                row
                // aria-labelledby="demo-row-radio-buttons-group-label"
                sx={{}}
                name="row-radio-buttons-group"
                value={updatedData.gender}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    gender: e.target.value,
                  }));
                }}
              >
                <Typography sx={Styles.gender}>Gender</Typography>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#005e89" },
                  }}
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#005e89" },
                  }}
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                {/* <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                      { color: "#005e89" },
                  }}
                  value="other"
                  control={<Radio />}
                  label="Other"
                /> */}
              </RadioGroup>
              {!isChecked && data?.memberCategory === "Member" && (
                <FormControl sx={[Styles.textField, { marginTop: "100px" }]}>
                  <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    InputLabelProps={{
                      sx: {
                        fontFamily: "MontserratRegular",
                      },
                    }}
                  >
                    Assign Group
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.assignGroup}
                    label="Assign Group"
                    size="small"
                    onChange={(e) => {
                      handleChange("assignGroup", e.target.value);
                    }}
                  >
                    {["Group", "Stand Alone", "Free"].map((item, index) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              )}
            </Box>

            <Box sx={Styles.inner2}>
              <Box style={Styles.uploadPhoto}>
                {/* <Typography sx={Styles.uploadText}>Upload Photo</Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {image ? (
                    <img style={Styles.img} src={image} alt="new" />
                  ) : (
                    <img style={Styles.img} src={imgUrl && !imgUrl.endsWith("null")&& !imgUrl.endsWith("/")
                    ? imgUrl
                    : require('../../images/avatar.png')} alt="upload image " />
                  )}

                  {update ? (
                    <button
                      style={Styles.uploadInput}
                      onClick={handleImageClick}
                    >
                      Upload New Image
                    </button>
                  ) : (
                    <button
                      style={Styles.uploadInput}
                      onClick={handleImageClick}
                    >
                      Upload Profile Image
                    </button>
                  )}
                </Box>
                {/* <img src={imgUrl} width={50} height={50} alt=""/> */}
              </Box>
              <Typography>{updatedData.profilePic}</Typography>

              <FormControlLabel
                // value={}
                sx={{
                  marginTop: "115px",
                  marginLeft: "10px",
                  fontFamily: "MontserratRegular",
                  "& .MuiFormControlLabel-label": {
                    fontFamily: "MontserratRegular",
                  },
                  "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked":
                    { color: "#005e89" },
                }}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Click the box to make a staff user."
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={Styles.headerText}>Address Details</Typography>
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Address Line 1"
                variant="outlined"
                value={updatedData.add1}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    add1: e.target.value,
                  }));
                }}
              />

              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Postal Code"
                variant="outlined"
                value={updatedData.postcode}
                required
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    postcode: e.target.value,
                  }));
                }}
                error={!!errors.postcode}
                helperText={errors.postcode}
              />
            </Box>
            <Box>
              <TextField
                size="small"
                sx={[Styles.textField, { marginTop: "50px" }]}
                id="outlined-basic"
                placeholder="Address Line 2"
                variant="outlined"
                value={updatedData.add2}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    add2: e.target.value,
                  }));
                }}
              />
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="State/ Province "
                variant="outlined"
                value={updatedData.state}
                required
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    state: e.target.value,
                  }));
                }}
                error={!!errors.state}
                helperText={errors.state}
              />
            </Box>
            <Box>
              <TextField
                size="small"
                sx={[Styles.textField, { marginTop: "50px" }]}
                id="outlined-basic"
                placeholder="City"
                required
                variant="outlined"
                value={updatedData.city}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    city: e.target.value,
                  }));
                }}
                error={!!errors.city}
                helperText={errors.city}
              />
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Country "
                variant="outlined"
                value={updatedData.country}
                required
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    country: e.target.value,
                  }));
                }}
                error={!!errors.country}
                helperText={errors.country}
              />
              <Box
                sx={{ width: "90%", marginLeft: "15px", marginBottom: "20px" }}
              >
                <FullRedButton click={handleNav} title="Submit" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
const Styles = {
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // minWidth: "336px",
    margin: "auto",
    height: "8vh",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  mainBox: {
    width: "100%",
    height: "620px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    "@media (max-width:768px)": {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  firstBox: {
    marginTop: "25px",
    width: "95%",
    // height: "570px",
    border: "1px solid #999999",
    borderRadius: "10px",
    "@media (max-width:768px)": {
      width: "90%",
      height: "100%",
      margin: "auto",
    },
  },
  headerBox: {
    width: "100%",
    height: "25px",
    borderBottom: "1px solid #999999",
    alignItems: "center",
    // padding:"auto"
  },
  headerText: {
    marginTop: "5px",
    margin: "15px",
    // marginLeft:"15px",
    padding: "auto",
    alignItems: "center",
    fontSize: "14px",
    color: "#252525",
    fontWeight: "bold",
    fontFamily: "MontserratRegular",
  },
  secondBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  inner1: {
    width: "33.33%",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  inner2: {
    marginTop: "51px",
    width: "33.33%",
    // height:"50px"
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  textField: {
    width: "90%",
    marginLeft: "15px",
    marginBottom: "15px",
    "& .MuiInputBase-root": {
      fontFamily: "MontserratRegular",
    },
  },
  gender: {
    fontSize: "16px",
    fontFamily: "MontserratMedium",
    // fontWeight: "bold",
    margin: "15px",
    marginLeft: "25px",
    marginRight: "55px",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  uploadPhoto: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'center',
    width: "95%",
    height: "150px",
    border: "1px solid #999999",
    borderRadius: "10px",
    margin: "auto",
    // display:"flex",
    // justifyContent:"center",
    "@media (max-width:768px)": {
      margin: "auto",
    },
  },
  uploadText: {
    margin: "10px",
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    color: "#999999",
  },
  uploadLogo: {
    width: "80px",
    marginLeft: "40%",
    cursor: "pointer",
  },
  uploaded: {
    width: "100%",
    bottom: "0px",
  },
  img: {
    height: "90px",
    width: "90px",
    marginTop: 10,
  },
  uploadInput: {
    border: "1px solid #005e89",
    fontFamily: "MontserratRegular",
    height: " 35px",
    width: "170px",
    backgroundColor: "#005e89",
    color: "#ffffff",
    borderRadius: "10px",
    margin: "10px",
    marginLeft: "0px",
    top: 20,
  },
};
