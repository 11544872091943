import * as React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import { Typography, Button } from "@mui/material";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
// import { useNavigate } from "react-router";
import ExistingUser from "../components/UserMangement/ExistingUser";
import UserRequest from "../components/UserMangement/UserRequest";
import RolesManagment from "../components/UserMangement/RolesManagement";
import { useUserDefaultRole } from "@nhost/react";
import styles from "../styles/global";

export default function UserManagement() {
  // const navigate = useNavigate();
  // function handleNav() {
  //   navigate("/adduser");
  // }

   const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const defaultRole = useUserDefaultRole();

  return (
    <Box sx={{ width: "100%" }}>
      <Navbar />
      <Box sx={Styles.topContainer}>
        <Typography sx={Styles.largeTxt}>User Configuration</Typography>

        
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box sx={Styles.tabsBox}>
          <TabContext value={value}>
            <Box
              sx={{
                width: "100%",
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList
                TabIndicatorProps={{ sx: { backgroundColor: "#a32c26" } }}
                sx={{
                  "& button:hover": {
                    backgroundColor: "#999999",
                    color: "white",
                  },
                  "& button:focus": {
                    backgroundColor: "#005e89",
                    fontFamily: "MontserratRegular",
                    color: "white",
                  },
                  "& button.Mui-selected": {
                    backgroundColor: "#005e89",
                    fontFamily: "MontserratRegular",
                    color: "white",
                  },
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  sx={{
                    fontFamily: "MontserratSemiBold",
                    border: "1px solid #999999",
                    borderRadius: "10px 0px 0px 0px",
                    width:'15%'
                  }}
                  label="Existing User"
                  value="1"
                />
                <Tab
                  sx={{
                    fontFamily: "MontserratSemiBold",
                    border: "1px solid #999999",
                    width:'15%'
                  }}
                  label="User Enquiry"
                  value="2"
                />
                 {defaultRole === "admin" &&
                <Tab
                  sx={{
                    fontFamily: "MontserratSemiBold",
                    border: "1px solid #999999",
                    width:'15%'
                  }}
                  label="Roles Management"
                  value="3"
                />
}
              </TabList>
            </Box>
            <TabPanel style={Styles.tab} value="1">
              <ExistingUser />
            </TabPanel>
            <TabPanel style={Styles.tab} value="2">
              <UserRequest />
            </TabPanel>
            <TabPanel style={Styles.tab} value="3">
              <RolesManagment />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
}
const Styles = {
  topContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    // height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
    lineHeight: 2.5,
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  tabsBox: {
    width: "95%",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px 10px 0px 0px",
    marginTop: "25px",
  },
  tab: {
    padding: "0px",
    
  },
};
