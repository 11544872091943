import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import deleteimg from "../../../images/delete.png";
import edit from "../../../images/editGray.png";
import DetailsEdit from "../GroupManagementEditPopups/DetailsEdit";
import { useState } from "react";
import { useEffect } from "react";
import { Api } from "@mui/icons-material";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { useUserEmail } from "@nhost/react";



const theme = createTheme({
  typography: {
    fontFamily: "MontserratRegular",
    marginLeft: "4px",
    color: "#999999",
    fontWeight: "bold",
  },
});

const GET_ALL_GROUP_QUERY = gql`query MyQuery15($_eq: bigint = "") {
  group_master(where: {id: {_eq: $_eq}}) {
    activation_date
    attendance_capacity
    category_id
    closure_date
    deactivation_date
    decription
    duration
    cover_img_path
    group_category {
      created_by
      created_date
      description
      id
      name
    }
    group_events {
      event_id
      group_id
      id
      event_master {
        activation_date
        category_id
        closure_date
        cover_img_path
        created_at
        deactivation_date
        description
        end_time
        id
        is_recurring
        location
        metadata
        name
        parent_id
        recurrance_end_date
        recurrance_end_time
        recurrance_frequency
        recurrance_start_time
        recurrance_start_date
        start_time
        type_id
        updated_at
        event_category {
          name
          id
        }
        event_type {
          name
          id
        }
      }
    }
    group_type {
      created_by
      created_date
      description
      id
      name
    }
    id
    level_up_group
    name
    price
    task_masters {
      description
      event_id
      file_path
      group_id
      id
      target_date
      staff_id
      task_name
      user {
        id
        displayName
        defaultRole
        email
        profile_detail {
          first_name
        }
      }
    }
    type
    user_groups {
      group_id
      id
      user_id
    }
  }
}
`;


const DELETE_EVENTQUERY = gql`
  mutation MyMutation3($_eq: bigint = "", $status: String = "") {
    update_group_master(
      where: { id: { _eq: $_eq } }
      _set: { status: $status }
    ) {
      returning {
        activation_date
      }
    }
  }
`;
const GET_GROUP_QUERY = gql`
  query getAllGroups {
    group_master(where: {status: {_eq: "isactive"}}) {
      activation_date
      attendance_capacity
      category_id
      closure_date
      deactivation_date
      decription
      id
      cover_img_path
      level_up_group
      name
      price
      publish_to
      type
    }
  }
`;
const INSERT_LOGS_MASTER = gql`
  mutation MyMutation5(
    $content_type: String = ""
    $deleted_by: String = ""
    $deleted_frmtable: String = ""
    $deleted_from: String = ""
    $deleted_ip: String = ""
    $deleted_region: String = ""
    $deleted_time: timestamptz = ""
  ) {
    insert_logs_master(
      objects: {
        content_type: $content_type
        deleted_by: $deleted_by
        deleted_frmtable: $deleted_frmtable
        deleted_from: $deleted_from
        deleted_ip: $deleted_ip
        deleted_region: $deleted_region
        deleted_time: $deleted_time
      }
    ) {
      returning {
        content_type
        deleted_by
        deleted_frmtable
        deleted_time
      }
    }
  }
`;
export default function PublishEventPopUp(props) {
  const { onClose, open } = props;
  console.log("prop",props)
  const navigate = useNavigate();
  const userName = useUserEmail();
  const currentTime = new Date();
  const [openpop, setOpenpop] = useState(false);
  const [openpopUp, setOpenpopUp] = useState(false);
  const openPopup = () => {
    console.log("only for popup");
    setOpenpopUp(!openpopUp);
    // props.setOpen(false);
  };

  const editEvents = () => {
    setOpenpop(!openpop);
  };

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const [role, setRole] = useState("");

//   console.log("Role by api", role);

  const [UserNameId, setUserNameId] = useState("");

  // ........................event details fetch................................

  const [Group, setGroup] = useState([]);
  const [tgroup , setTgroup] = useState([]);

//   console.log("Events by api", Events);

const { loading, error, data } = useQuery(GET_ALL_GROUP_QUERY, {
  variables: {
    _eq:props.id,
  },
});


  useEffect(() => {
    setGroup(data?.group_master[0]);
    console.log("group data", data?.group_master[0]);
  }, []);


  const { data:data1 , refetch } = useQuery(GET_GROUP_QUERY);
  useEffect(() => {
    if (data1) {
      setTgroup(data1.group_master);
    }
  }, [data1]);
  // ........................event details fetch................................
  const [insertLogs] = useMutation(INSERT_LOGS_MASTER);

  const [deleteevent] = useMutation(
    DELETE_EVENTQUERY,

    {
      onCompleted: (data) => {
        navigate("/groupmanagement", { state: { isdeleted: true } });
        refetch();
      },

      onError: (error) => {
        console.error("Mutation Error:", error.message);
      },
      refetchQueries:[

        {query:GET_GROUP_QUERY}
      ]
    }
  );

  const deletevent = async () => {
    console.log(props.id, "id we are deleting");

    const confirmation = window.confirm(`Are you sure you want to delete ?`);

    if (confirmation) {
      try {
        const { data: insertedData } = await deleteevent({
          variables: {
            _eq: props.id,
            status: "isnotactive",
          },
        });
        const { data: insertedDataw } = await insertLogs({
          variables: {
            content_type: props.name,
            deleted_by: userName,
            deleted_frmtable:"group_master",
            deleted_from:"group",
            deleted_ip:"-",
            deleted_region:'-',
            deleted_time:currentTime,
          },
        });
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        sx={{
          marginTop: "0px",

          marginLeft: "78%",

          marginBottom: "18%",
        }}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ display: "flex", flexDirection: "column", margin: "auto" }}>
        
        <Box
            sx={{
              display: "flex",

              flexDirection: "row",

              margin: "8px",

              alignItems: "center",
              cursor:"pointer",
            }}
            onClick={openPopup}
          >
            <img src={edit} alt="delete" width="20px" height="20px" />

            <Typography sx={{ marginLeft: "4px" }}>Edit Course</Typography>
          </Box>
        
          <Box
            sx={{
              display: "flex",

              flexDirection: "row",

              margin: "8px",

              alignItems: "center",
              cursor:"pointer",

            }}
            onClick={deletevent}
          >
            <img src={deleteimg} alt="delete" width="20px" height="20px" />

            <Typography sx={{ marginLeft: "4px" }}>Delete Course</Typography>
          </Box>

          {openpopUp && (
          <DetailsEdit
            attendanceCapacity={Group?.attendance_capacity}
            price={Group?.price}
            duration={Group?.duration}
            type={Group?.group_type?.id}
            id={Group?.id}
            open={openpopUp}
            setOpen={setOpenpopUp}
            name={Group?.name}
            description={Group?.decription}
            grpCat={Group?.group_category?.id}
            
            // startDate={startDate}
            // endDate={endDate}
            // cover_img_path={grpImg}
            coverImgId={Group?.cover_img_path}
            setOpenPop={props.setOpen}
            urlImg={props.urlImage}
          />
        )}

        </Box>
      </Dialog>
    </ThemeProvider>
  );
}
