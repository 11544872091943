import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import calender from "../../images/EventManagement1/Calender.png"
import location from "../../images/EventManagement1/Location.png"
import { useNhostClient } from '@nhost/react'
import nullimage from '../../images/nullImage.jpeg'


export default function EventManagementCard(props) {
// console.log("cove image props" , props)
  const nhost = useNhostClient()

  const [imgUrl, setImgUrl] = useState('')

  const { title, desc, date, time, address, cover_img_path } = props;

  useEffect(() => {

    async function setImage() {

    //   nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)
    //   // nhost.storage.setAccessToken(res1.fileMetadata.id)

    //   console.log(cover_img_path)

    //   const publicUrl = await nhost.storage.getPresignedUrl({
    //     fileId: cover_img_path,
    //   })

    //   console.log(publicUrl)
    //   setImgUrl(publicUrl.presignedUrl.url)
    try {

      const item = await nhost.storage.getPublicUrl({

        fileId: cover_img_path,

      });

      // console.log("Image URL:", item);
// setImgPreview(item)

setImgUrl(item)

    } catch (error) {

      console.error("Error retrieving image URL:", error);

    }

    }


    setImage()

  }, [cover_img_path])

  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const year = dateObject.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  const formattedStartDate = `${formattedDate}`;

 
  return (
    <Box sx={styles.container}>
      {/* .................img container */}
      <Box sx={styles.imgContainer}>
        {/* <img src={
           imgUrl && !imgUrl.endsWith("null")&& !imgUrl.endsWith("/")
           ? imgUrl
           : nullimage
              }  width="250px" style={{ height: '100px', borderTopLeftRadius: "10px", WebkitBorderTopRightRadius: "10px" }} /> */}
      <img src={require('../../images/convenelogo.png')    }  width="150px" style={{ height: '100px', borderTopLeftRadius: "10px", WebkitBorderTopRightRadius: "10px" ,marginLeft:'15%'}} /> 
    
     
      </Box>
      {/* .................img container ends */}

      {/*.................text container */}
      <Box sx={styles.textContainer}>
        <Typography sx={styles.titleTxt}>{title}</Typography>
        <Typography sx={styles.desc}>{desc}</Typography>

        <Box sx={styles.dateTime}>
          <img style={{ width: '15px', height: '15px' }} src={calender} alt="calender" />
          <Typography sx={[styles.baseTxt, { marginRight: '20px', marginLeft: '2px' }]}>{formattedStartDate}</Typography>
          <Typography sx={styles.baseTxt}>{time}</Typography>
        </Box>

        <Box sx={styles.dateTime}>
          <img style={{ width: '17px', height: '17px' }} src={location} alt="location" />
          <Typography sx={styles.baseTxt}>{address}</Typography>
        </Box>
      </Box>



    </Box>
  )
}

const styles = {
  container: {
    width: '250px',
    borderRadius: '10px',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    height: '200px',
    margin: '10px',

  },
  imgContainer: {
    width: '250px',
    height:'50%',
  
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '2px',
    width: '100%',
  },
  titleTxt: {
    fontFamily: "MontserratRegular",
    fontSize: '10px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#252525',
    lineHeight: '20px',
  },
  desc: {
    fontFamily: "MontserratRegular",
    fontSize: '9px',
    textAlign: 'left',
    fontWeight: '400',
    color: '#414042',
    height: "30px",
    lineHeight: '14.63px',
    overflow: 'hidden'
  },
  dateTime: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent:'space-between',
    alignItems: 'center',
    width: '100%',
    // margin:'2px auto 2px auto',
  },
  baseTxt: {
    fontFamily: "MontserratRegular",
    fontSize: '8px',
    fontWeight: '500',
    color: '#414042',
    lineHeight: '12.19px',
    height: "10px",
    overflow: 'hidden',
  }
}