import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, gql } from "@apollo/client";

const INSERT_FACILITATOR_ROLE = gql`
  mutation InsertFacilitatorRole(
    $created_by: String!
    $description: String!
    $name: String!
  ) {
    insert_facilitator_roles(
      objects: {
        created_by: $created_by
        description: $description
        name: $name
      }
    ) {
      returning {
        created_at
        created_by
        description
        id
        name
      }
    }
  }
`;

const UPDATE_FACILITATOR_ROLE = gql`
  mutation UpdateFacilitatorRole(
    $id: bigint!
    $name: String!
    $description: String!
    $created_by: String!
  ) {
    update_facilitator_roles(
      where: { id: { _eq: $id } }
      _set: { description: $description, name: $name, created_by: $created_by }
    ) {
      returning {
        created_at
        created_by
        description
        id
        name
      }
    }
  }
`;

export default function AddFacillatorRole(props) {
  const [open, setOpen] = React.useState(false);
  const [insertFacilitatorRole] = useMutation(INSERT_FACILITATOR_ROLE);
  const [updateFacilitatorRole] = useMutation(UPDATE_FACILITATOR_ROLE);

  const [data, setData] = useState(props);

  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleClickOpen = () => {
    props.setNewTaskOpen(true);
  };

  const handleClose = () => {
    props.setNewTaskOpen(false);
  };

  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = React.useState();

  const handleSave = async () => {
    if (props.isforUpdate) {
      try {
        const { data: updateData } = await updateFacilitatorRole({
          variables: {
            id: props.id,
            description: data["description"],
            name: data["facilitator_role"],
            created_by: localStorage.getItem("email"),
          },
        });
        console.log(updateData);
        props.setNewTaskOpen(false);
        props.setUpdated(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const { data: insertData } = await insertFacilitatorRole({
          variables: {
            description: data["description"],
            name: data["facilitator_role"],
            created_by: localStorage.getItem("email"),
          },
        });
        console.log(insertData);
        props.setfacilitatorRole((prevData) => {
          return [
            ...prevData,
            insertData.insert_facilitator_roles.returning[0],
          ];
        });
        props.setNewTaskOpen(false);
        } catch (error) {
        console.error(error);
      }
    }
  };

  const resetFields = () => {
    setData({
      facilitator_role: "", // Reset event_type field
      description: "", // Reset description field
    });
    setError("");
  };

  React.useEffect(() => {
    if (!props.isforUpdate) {
      resetFields(); // Reset fields when opening the popup for adding a new event type
    }
  }, [props.isforUpdate]);

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <Box sx={{ backgroundColor: "#999999", height: "100%" }}>
          <Typography sx={{ m: 1, color: "#FFFFFF" }}>
            Add Facilitator Role
          </Typography>
        </Box>
        <Box sx={{ mx: 3, my: 1 }}>
          <TextField
            id="outlined-textarea"
            placeholder="Role Name"
            size="small"
            multiline
            variant="outlined"
            sx={{ width: 360 }}
            value={data.facilitator_role}
            onChange={(e) => {
              handleChange("facilitator_role", e.target.value);
            }}
          />
        </Box>
        {error && (
          <Typography sx={{ color: "red", marginLeft: 5 }}>
            Please Fill the values
          </Typography>
        )}
        <Box sx={{ mx: 3, my: 1 }}>
          <TextField
            id="outlined-multiline-static"
            //   label="Multiline"
            placeholder="Description"
            sx={{ width: 360 }}
            multiline
            rows={4}
            variant="outlined"
            value={data.description}
            onChange={(e) => {
              handleChange("description", e.target.value);
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            m: 1,
          }}
        >
          <Button
            sx={{
              border: 1,
              width: "100px",
              height: 30,
              mx: 1,
              borderColor: "#999999",
              color: "#999999",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              ml: 2,
              width: "100px",
              color: "#FFFFFF",
              backgroundColor: "#005e89",
              "&:hover": {
                backgroundColor: "#005e89",
                color: "#FFFFFF",
              },
              height: 30,
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}
